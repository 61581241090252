@font-face {
	font-family: 'stroyka';
	src: url('stroyka.ttf') format('truetype'), url('stroyka.woff') format('woff'),
		url('stroyka.svg#stroyka') format('svg');
	font-weight: normal;
	font-style: normal;
}

@keyframes a {
	0% {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(1turn);
	}
}
body,
html {
	height: 100%;
}
body {
	font-family: Roboto, 'sans-serif';
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	background: #fff;
	color: #3d464d;
	text-align: left;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	overflow-y: scroll;
}
a,
a:hover {
	color: #1b68a4;
}
a:hover {
	text-decoration: none;
}
label {
	margin-bottom: 0.25rem;
}
h1 {
	font-size: 36px;
}
h1,
h2 {
	font-weight: 700;
}
h2 {
	font-size: 32px;
}
h3 {
	font-size: 28px;
}
h3,
h4 {
	font-weight: 700;
}
h4 {
	font-size: 24px;
}
h5 {
	font-size: 20px;
}
h5,
h6 {
	font-weight: 700;
}
h6 {
	font-size: 16px;
}
.document {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
	padding: 85px 200px 120px;
}
.document__header {
	text-align: center;
	margin-bottom: 80px;
}
.document__subtitle {
	margin-top: 12px;
	font-size: 14px;
	line-height: 22px;
	color: #999;
}
@media (max-width: 1199px) {
	.document {
		padding: 75px 110px 110px;
	}
	.document__header {
		margin-bottom: 60px;
	}
}
@media (max-width: 991px) {
	.document {
		padding: 45px 35px 90px;
	}
	.document__header {
		margin-bottom: 40px;
	}
}
@media (max-width: 767px) {
	.document {
		padding: 35px 30px 80px;
	}
	.document__header {
		margin-bottom: 30px;
	}
	.document__subtitle {
		margin-top: 6px;
	}
}
@media (max-width: 575px) {
	.document {
		padding: 26px 20px 40px;
	}
	.document__header {
		text-align: left;
	}
}
.fake-svg-icon {
	font-family: stroyka !important;
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: none;
	vertical-align: top;
}
.fake-svg-icon--wishlist-16 {
	width: 16px;
	height: 16px;
	font-size: 16px;
}
.fake-svg-icon--wishlist-16:before {
	content: '\e904';
}
.fake-svg-icon--compare-16 {
	width: 16px;
	height: 16px;
	font-size: 16px;
}
.fake-svg-icon--compare-16:before {
	content: '\e901';
}
.page-header__container {
	padding-bottom: 32px;
}
.page-header__breadcrumb {
	padding-top: 16px;
}
.page-header__title {
	padding-top: 12px;
}
.site {
	min-height: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.site__header {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.site__body {
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.site__footer {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.alert {
	border: none;
	border-radius: 2px;
	font-size: 15px;
	line-height: 20px;
	padding: 0.75rem 1rem;
}
.alert a {
	box-decoration-break: clone;
	-webkit-box-decoration-break: clone;
	color: currentColor;
	background: hsla(0, 0%, 100%, 0.5);
	border-radius: 1.5px;
	padding: 0 3px;
}
.alert a:hover {
	background: hsla(0, 0%, 100%, 0.85);
}
.alert a:active {
	background: hsla(0, 0%, 100%, 0.5);
}
.alert-primary {
	background: #1B68A4;
	color: #fff;
	text-align: center;
}
.alert-secondary {
	background: #d9d9d9;
	color: #4d4d4d;
}
.alert-info {
	background: #b8e2e5;
	color: #2d3e40;
}
.alert-success {
	background: #bde5a1;
	color: #34402d;
}
.alert-danger {
	background: #ffb8b8;
	color: #473333;
}
.alert-warning {
	background: #f2e291;
	color: #4d472e;
}
.alert-lg {
	font-size: 16px;
	padding: 1.125rem 1.375rem;
}
.alert-dismissible {
	padding-right: 3rem;
}
.alert-dismissible .close {
	opacity: 1;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 0 1rem;
	height: 100%;
	transition: all 0.2s;
	fill: rgba(0, 0, 0, 0.3);
	border-radius: 0 1.5px 1.5px 0;
	background: transparent;
}
.alert-dismissible .close:focus {
	outline: none;
}
.alert-dismissible .close:not(:disabled):not(.disabled):focus,
.alert-dismissible .close:not(:disabled):not(.disabled):hover {
	background: rgba(0, 0, 0, 0.08);
	fill: rgba(0, 0, 0, 0.5);
	opacity: 1;
}
.badge {
	border-radius: 1.5px;
	font-weight: 500;
	padding: 0.25em 0.5em;
}
.block-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 24px;
}
.block-header__title {
	margin-bottom: 0;
	font-size: 20px;
}
.block-header__divider {
	-ms-flex-positive: 1;
	flex-grow: 1;
	height: 2px;
	background: #ebebeb;
}
.block-header__groups-list {
	display: -ms-flexbox;
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 500;
	white-space: nowrap;
}
.block-header__groups-list li + li {
	margin-left: 3px;
}
.block-header__group {
	background: transparent;
	border: none;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	cursor: pointer;
	display: block;
	color: inherit;
	padding: 2px 11px 1px;
	border-radius: 12px;
	box-shadow: none;
}
.block-header__group:focus {
	outline: none;
}
.block-header__group:focus,
.block-header__group:hover {
	box-shadow: inset 0 0 0 2px #ebebeb;
	color: inherit;
}
.block-header__group--active,
.block-header__group--active:focus,
.block-header__group--active:hover {
	cursor: default;
	box-shadow: inset 0 0 0 2px #1b68a4;
}
.block-header__arrow,
.block-header__arrows-list {
	display: -ms-flexbox;
	display: flex;
}
.block-header__arrow {
	width: 27px;
	height: 29px;
	padding: 0;
	margin: 0;
	border: none;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	cursor: pointer;
	fill: #999;
	border-radius: 1.5px;
	background: #f2f2f2;
	transition: all 0.15s;
}
.block-header__arrow:focus {
	outline: none;
}
.block-header__arrow:focus,
.block-header__arrow:hover {
	background: #1b68a4;
	color: #fff;
}
.block-header__arrow:active {
	transition-duration: 0s;
	background: #ffdc5c;
	fill: #3d464d;
}
.block-header__arrow + .block-header__arrow {
	margin-left: 3px;
}
.block-header__arrow--left svg {
	position: relative;
	left: -1px;
}
.block-header__arrow--right svg {
	position: relative;
	left: 1px;
}
.block-header__title + .block-header__divider {
	margin-left: 16px;
}
.block-header__divider + .block-header__groups-list {
	margin-left: 12px;
}
.block-header__groups-list + .block-header__arrows-list {
	margin-left: 10px;
}
.block-header__divider + .block-header__arrows-list {
	margin-left: 16px;
}
@media (max-width: 767px) {
	.block-header {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.block-header__groups-list {
		padding: 8px 0;
		margin-top: 8px;
		margin-bottom: -8px;
		-ms-flex-order: 100;
		order: 100;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.block-header__divider + .block-header__groups-list {
		margin-left: auto;
		margin-right: auto;
	}
	.block-header__divider
		+ .block-header__groups-list
		+ .block-header__arrows-list {
		margin-left: 16px;
	}
}
.breadcrumb {
	background: transparent;
	padding: 0;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 0;
}
.breadcrumb-item {
	position: relative;
	padding-right: 5px;
}
.breadcrumb-item a {
	color: inherit;
	transition: color 0.15s;
}
.breadcrumb-item a:hover {
	color: #1b68a4;
}
.breadcrumb-item + .breadcrumb-item {
	padding-left: 0;
}
.breadcrumb-item + .breadcrumb-item:before {
	display: none;
}
.breadcrumb-item.active {
	color: #999;
}
.breadcrumb-arrow {
	fill: #d1d1d1;
	position: absolute;
	right: 11px;
	top: 5px;
}
.btn {
	border-radius: 2px;
	transition: all 0.1s ease-in-out;
	font-size: 1rem;
	height: calc(2.25rem + 2px);
	line-height: 1.5;
	padding: 0.375rem 1.25rem;
	font-weight: 500;
}
.btn.btn-svg-icon {
	width: calc(2.25rem + 2px);
}
.btn-lg {
	font-size: 1.125rem;
	height: calc(2.75rem + 2px);
	padding: 0.8125rem 2rem;
	line-height: 1;
	font-weight: 500;
}
.btn-lg.btn-svg-icon {
	width: calc(2.75rem + 2px);
}
.btn-xl {
	font-size: 1.375rem;
	height: calc(3.625rem + 2px);
	padding: 1.125rem 3rem;
	line-height: 1;
	font-weight: 500;
}
.btn-xl.btn-svg-icon {
	width: calc(3.625rem + 2px);
}
.btn-sm {
	font-size: 0.875rem;
	height: calc(1.875rem + 2px);
	line-height: 1.25;
	padding: 0.375rem 1rem;
	font-weight: 500;
}
.btn-sm.btn-svg-icon {
	width: calc(1.875rem + 2px);
}
.btn-xs {
	font-size: 0.8125rem;
	height: calc(1.5rem + 2px);
	line-height: 1.25;
	padding: 0.25rem 0.5625rem;
	font-weight: 500;
}
.btn-xs.btn-svg-icon {
	width: calc(1.5rem + 2px);
}
.btn-svg-icon {
	padding: 0;
	position: relative;
}
.btn-svg-icon svg {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.btn-svg-icon--fake-svg .fake-svg-icon {
	transition: all 0.1s ease-in-out;
	display: none;
}
@supports (-ms-ime-align: auto) {
	.btn-svg-icon--fake-svg svg {
		display: none;
	}
	.btn-svg-icon--fake-svg .fake-svg-icon {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		display: block;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
	border-color: #3d464d;
	background: #3d464d;
	color: #fff;
	fill: #fff;
}
.btn-primary.focus .fake-svg-icon,
.btn-primary:focus .fake-svg-icon,
.btn-primary:hover .fake-svg-icon {
	color: #fff;
}
.btn-primary.focus,
.btn-primary:focus {
	box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
	border-color: #546069;
	background: #546069;
	color: #fff;
	fill: #fff;
}
.btn-primary:not(:disabled):not(.disabled).active .fake-svg-icon,
.btn-primary:not(:disabled):not(.disabled):active .fake-svg-icon {
	color: #fff;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
	box-shadow: none;
}
.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
	border-color: #1b68a4;
	background: #1b68a4;
	color: #f2f2f2;
	fill: #3d464d;
}
.btn-primary.disabled .fake-svg-icon,
.btn-primary .fake-svg-icon,
.btn-primary:disabled .fake-svg-icon {
	color: #3d464d;
}
.btn-primary.btn-loading:not(:disabled):not(.disabled),
.btn-primary.btn-loading:not(:disabled):not(.disabled).active,
.btn-primary.btn-loading:not(:disabled):not(.disabled):active {
	cursor: default;
	border-color: #1b68a4;
	background: #1b68a4;
}
.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover {
	border-color: #3d464d;
	background: #3d464d;
	color: #fff;
	fill: #fff;
}
.btn-secondary.focus .fake-svg-icon,
.btn-secondary:focus .fake-svg-icon,
.btn-secondary:hover .fake-svg-icon {
	color: #fff;
}
.btn-secondary.focus,
.btn-secondary:focus {
	box-shadow: none;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
	border-color: #546069;
	background: #546069;
	color: #fff;
	fill: #fff;
}
.btn-secondary:not(:disabled):not(.disabled).active .fake-svg-icon,
.btn-secondary:not(:disabled):not(.disabled):active .fake-svg-icon {
	color: #fff;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
	box-shadow: none;
}
.btn-secondary,
.btn-secondary.disabled,
.btn-secondary:disabled {
	border-color: #f0f0f0;
	background: #f0f0f0;
	color: #3d464d;
	fill: #3d464d;
}
.btn-secondary.disabled .fake-svg-icon,
.btn-secondary .fake-svg-icon,
.btn-secondary:disabled .fake-svg-icon {
	color: #3d464d;
}
.btn-secondary.btn-loading:not(:disabled):not(.disabled),
.btn-secondary.btn-loading:not(:disabled):not(.disabled).active,
.btn-secondary.btn-loading:not(:disabled):not(.disabled):active {
	cursor: default;
	border-color: #f0f0f0;
	background: #f0f0f0;
}
.btn-light.focus,
.btn-light:focus,
.btn-light:hover {
	border-color: #f0f0f0;
	background: #f0f0f0;
	color: #3d464d;
	fill: #3d464d;
}
.btn-light.focus .fake-svg-icon,
.btn-light:focus .fake-svg-icon,
.btn-light:hover .fake-svg-icon {
	color: #3d464d;
}
.btn-light.focus,
.btn-light:focus {
	box-shadow: none;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active {
	border-color: #e5e5e5;
	background: #e5e5e5;
	color: #3d464d;
	fill: #3d464d;
}
.btn-light:not(:disabled):not(.disabled).active .fake-svg-icon,
.btn-light:not(:disabled):not(.disabled):active .fake-svg-icon {
	color: #3d464d;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus {
	box-shadow: none;
}
.btn-light,
.btn-light.disabled,
.btn-light:disabled {
	border-color: transparent;
	background: transparent;
	color: #3d464d;
	fill: #ccc;
}
.btn-light.disabled .fake-svg-icon,
.btn-light .fake-svg-icon,
.btn-light:disabled .fake-svg-icon {
	color: #ccc;
}
.btn-light.btn-loading:not(:disabled):not(.disabled),
.btn-light.btn-loading:not(:disabled):not(.disabled).active,
.btn-light.btn-loading:not(:disabled):not(.disabled):active {
	cursor: default;
	border-color: transparent;
	background: transparent;
}
.btn-loading {
	position: relative;
}
.btn-loading,
.btn-loading.disabled,
.btn-loading.focus,
.btn-loading:disabled,
.btn-loading:focus,
.btn-loading:hover,
.btn-loading:not(:disabled):not(.disabled).active,
.btn-loading:not(:disabled):not(.disabled):active {
	color: transparent;
	fill: transparent;
}
.btn-loading:after {
	display: block;
	content: '';
	position: absolute;
	border: 2px solid rgba(0, 0, 0, 0.1);
	border-top-color: rgba(0, 0, 0, 0.5);
	transition: border-color 0.1s;
	animation-name: a;
	animation-duration: 0.5s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	left: calc(50% - 11px);
	top: calc(50% - 11px);
	width: 22px;
	height: 22px;
	border-radius: 11px;
}
.btn-loading.btn-xl:after {
	left: calc(50% - 21px);
	top: calc(50% - 21px);
	width: 42px;
	height: 42px;
	border-radius: 21px;
}
.btn-loading.btn-lg:after {
	left: calc(50% - 14px);
	top: calc(50% - 14px);
	width: 28px;
	height: 28px;
	border-radius: 14px;
}
.btn-loading.btn-sm:after {
	left: calc(50% - 8px);
	top: calc(50% - 8px);
	width: 16px;
	height: 16px;
	border-radius: 8px;
}
.btn-loading.btn-xs:after {
	left: calc(50% - 7px);
	top: calc(50% - 7px);
	width: 14px;
	height: 14px;
	border-radius: 7px;
}
.card {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
}
.card-body {
	padding: 2rem;
}
.card-title {
	margin-bottom: 1.75rem;
}
.card-divider {
	height: 2px;
	background: #f0f0f0;
}
.form-group {
	margin-bottom: 1.5rem;
}
.form-check {
	padding-left: 1.5rem;
}
.form-check-input {
	position: absolute;
	margin-top: 0.1875rem;
	margin-left: -1.5rem;
}
.form-control {
	border-radius: 2px;
	line-height: 1.25;
}
.form-control-sm {
	height: calc(1.875rem + 2px);
	line-height: 1.25;
	padding: 0.3125rem 0.5rem;
}
.form-control-lg {
	height: calc(2.75rem + 2px);
	line-height: 1.25;
}
.form-control.is-invalid,
.form-control.is-valid,
.form-control:invalid,
.form-control:valid {
	background-image: none;
}
select.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
}
select.form-control::-ms-expand {
	display: none;
}
select.form-control,
select.form-control.is-invalid,
select.form-control.is-valid,
select.form-control:invalid,
select.form-control:valid {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 10'%3E%3Cpath fill='%234d4d4d' d='M4.503 4H.497c-.403 0-.639-.508-.408-.878L2.095.233a.469.469 0 0 1 .816 0l2 2.889c.231.37-.005.878-.408.878zM.497 6h4.006c.403 0 .639.504.408.871L2.905 9.737a.47.47 0 0 1-.816 0l-2-2.866C-.142 6.504.094 6 .497 6z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size: 5px 10px;
	background-position: right 0.75rem center;
	padding-right: calc(0.75rem * 2 + 5px);
}
select.form-control-sm,
select.form-control-sm.is-invalid,
select.form-control-sm.is-valid,
select.form-control-sm:invalid,
select.form-control-sm:valid {
	background-position: right 0.5rem center;
	padding-right: calc(0.5rem * 2 + 5px);
}
select.form-control-lg,
select.form-control-lg.is-invalid,
select.form-control-lg.is-valid,
select.form-control-lg:invalid,
select.form-control-lg:valid {
	background-position: right 1rem center;
	padding-right: calc(1rem * 2 + 5px);
}
.input-check {
	display: inline-block;
}
.input-check__body {
	display: block;
	position: relative;
	width: 16px;
	height: 16px;
}
.input-check__input {
	opacity: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	cursor: inherit;
}
.input-check__box {
	display: block;
	width: 16px;
	height: 16px;
	border-radius: 1.2px;
	background: #fff;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
	transition: background 0.15s, box-shadow 0.15s;
}
.input-check__icon {
	position: absolute;
	left: 4px;
	top: 5px;
	fill: #fff;
	transform: scale(0);
	transition: fill 0.15s, transform 0.15s, visibility 0s 0.15s;
}
.input-check__input:checked ~ .input-check__box {
	background: #1b68a4;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.input-check__input:checked ~ .input-check__icon {
	fill: #3d464d;
	transition-delay: 0s, 0s, 0s;
	transform: scale(1);
}
.input-check__input:disabled ~ .input-check__box {
	background: #f2f2f2;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.input-check__input:disabled ~ .input-check__icon {
	fill: rgba(0, 0, 0, 0.2);
}
.input-check-color {
	display: inline-block;
}
.input-check-color__body {
	display: block;
	position: relative;
	width: 22px;
	height: 22px;
	border-radius: 1.2px;
	overflow: hidden;
	cursor: inherit;
	margin: 0;
}
.input-check-color__input {
	position: absolute;
	visibility: hidden;
}
.input-check-color__box {
	display: block;
	width: 22px;
	height: 22px;
	border-radius: 1.2px;
	background: currentColor;
	cursor: inherit;
}
.input-check-color__icon {
	position: absolute;
	left: 5px;
	top: 6px;
	fill: #fff;
	transform: scale(0);
	transition: transform 0.15s;
}
.input-check-color__input:checked ~ .input-check-color__icon {
	transform: scale(1);
}
.input-check-color__input:disabled {
	cursor: default;
}
.input-check-color__input:disabled ~ .input-check-color__box {
	position: relative;
	left: 0;
	top: 0;
	width: 22px;
	height: 22px;
	opacity: 0.5;
	cursor: default;
}
.input-check-color__input:disabled ~ .input-check-color__stick {
	pointer-events: none;
	display: block;
	position: absolute;
	width: 2px;
	border-radius: 1px;
	height: 34px;
	left: calc(50% - 1px);
	top: -6px;
	background: hsla(0, 0%, 40%, 0.9);
	transform: rotate(45deg);
	transform-origin: center center;
}
.input-check-color--light .input-check-color__icon {
	fill: #3d464d;
}
.input-check-color--white .input-check-color__box {
	box-shadow: inset 0 0 0 2px #d9d9d9;
}
.input-check-color--white .input-check-color__icon {
	fill: #bfbfbf;
}
.input-number {
	display: block;
	width: 100%;
	position: relative;
}
.input-number__input {
	-moz-appearance: textfield;
	display: block;
	width: 100%;
	min-width: 88px;
	padding: 0 24px 1px;
	text-align: center;
}
.input-number__input::-webkit-inner-spin-button,
.input-number__input::-webkit-outer-spin-button {
	-webkit-appearance: none;
}
.input-number__add,
.input-number__sub {
	position: absolute;
	height: 100%;
	width: 24px;
	top: 0;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	opacity: 0.3;
	transition: opacity 0.18s;
}
.input-number__add:after,
.input-number__add:before,
.input-number__sub:after,
.input-number__sub:before {
	display: block;
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	background: currentColor;
}
.input-number__add:before,
.input-number__sub:before {
	width: 8px;
	height: 2px;
}
.input-number__add:hover,
.input-number__sub:hover {
	opacity: 1;
}
.input-number__sub {
	left: 1px;
}
.input-number__add {
	right: 1px;
}
.input-number__add:after {
	width: 2px;
	height: 8px;
}
.input-radio {
	display: inline-block;
}
.input-radio__body {
	display: block;
	position: relative;
	width: 16px;
	height: 16px;
}
.input-radio__input {
	opacity: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	cursor: inherit;
}
.input-radio__circle {
	display: block;
	width: 16px;
	height: 16px;
	border-radius: 8px;
	background: #fff;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.17);
	transition: background 0.2s, box-shadow 0.2s;
}
.input-radio__circle:after {
	display: block;
	content: '';
	position: absolute;
	left: 5px;
	top: 5px;
	width: 6px;
	height: 6px;
	border-radius: 3px;
	background: #fff;
	transform: scale(0);
	transition: background 0.2s, transform 0.2s, visibility 0s 0.2s;
	visibility: hidden;
}
.input-radio__input:checked ~ .input-radio__circle {
	background: #1b68a4;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
}
.input-radio__input:checked ~ .input-radio__circle:after {
	background: #fff;
	visibility: visible;
	transition-delay: 0s, 0s, 0s;
	transform: scale(1);
}
.input-radio__input:disabled ~ .input-radio__circle {
	background: #f2f2f2;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.12);
}
.input-radio__input:disabled ~ .input-radio__circle:after {
	background: rgba(0, 0, 0, 0.2);
}
.input-radio-color {
	display: block;
}
.input-radio-color__list {
	padding: 1px 0;
	display: -ms-flexbox;
	display: flex;
	margin: -2px;
}
.input-radio-color__item {
	width: 30px;
	height: 30px;
	display: block;
	margin: 2px;
	cursor: pointer;
}
.input-radio-color__item input {
	pointer-events: none;
	opacity: 0;
	position: absolute;
}
.input-radio-color__item span {
	position: relative;
	width: 30px;
	height: 30px;
	display: block;
	border-radius: 15px;
	background: currentColor;
	transform: scale(0.73333);
	transition: transform 0.2s;
}
.input-radio-color__item span:after,
.input-radio-color__item span:before {
	display: block;
	position: absolute;
	content: '';
	border-radius: 1000px;
}
.input-radio-color__item span:before {
	left: 3px;
	top: 3px;
	width: 24px;
	height: 24px;
	background: #fff;
	transform: scale(0);
	transition: transform 0.2s;
}
.input-radio-color__item span:after {
	left: 8px;
	top: 8px;
	width: 14px;
	height: 14px;
	background: currentColor;
	transform: scale(0);
	transition: transform 0.2s;
}
.input-radio-color__item:hover input:enabled ~ span {
	transform: scale(0.86667);
}
.input-radio-color__item input:enabled:checked ~ span,
.input-radio-color__item input:enabled:checked ~ span:after,
.input-radio-color__item input:enabled:checked ~ span:before {
	transform: scale(1);
}
.input-radio-color__item--disabled {
	cursor: default;
}
.input-radio-color__item--disabled span {
	transform: scale(0.75);
}
.input-radio-color__item--disabled span:before {
	transform: scale(1.33) rotate(45deg);
	left: 14px;
	top: -1px;
	width: 2px;
	height: calc(100% + 2px);
	background: rgba(0, 0, 0, 0.3);
}
.input-radio-color__item--white span,
.input-radio-color__item--white span:after {
	box-shadow: inset 0 0 0 2px #ccc;
}
.input-radio-label {
	display: block;
	font-size: 14px;
	font-weight: 500;
}
.input-radio-label__list {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: -3px;
}
.input-radio-label__list input {
	position: absolute;
	pointer-events: none;
	opacity: 0;
}
.input-radio-label__list label {
	display: -ms-flexbox;
	display: flex;
	margin: 3px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.input-radio-label__list span {
	cursor: pointer;
	display: block;
	height: 32px;
	padding: 0.3125rem 1rem;
	border-radius: 2px;
	background: #f0f0f0;
	color: #3d464d;
	transition: background-color 0.2s, color 0.2s;
}
.input-radio-label__list input:not(:checked):not(:disabled) ~ span:hover {
	background: #e0e0e0;
	color: #3d464d;
}
.input-radio-label__list input:checked ~ span {
	cursor: default;
	background: #1b68a4;
	color: #fcfcfc;
	transition-duration: 0.08s, 0.08s;
}
.input-radio-label__list input:disabled ~ span {
	cursor: default;
	opacity: 0.5;
}
.layout-switcher__button,
.layout-switcher__list {
	display: -ms-flexbox;
	display: flex;
}
.layout-switcher__button {
	border: 1px solid #e5e5e5;
	cursor: pointer;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 0 9px;
	height: 32px;
	border-radius: 0;
	background: #fff;
	fill: #a1abb3;
}
.layout-switcher__button:focus {
	outline: none;
}
.layout-switcher__button:hover {
	border-color: #e5e5e5;
	background: #f2f2f2;
	fill: #3d464d;
}
.layout-switcher__button:first-child {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}
.layout-switcher__button:last-child {
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}
.layout-switcher__button + .layout-switcher__button {
	margin-left: -1px;
}
.layout-switcher__button--active,
.layout-switcher__button--active:hover {
	cursor: default;
	border-color: #1b68a4;
	background: #1b68a4;
	fill: #3d464d;
	z-index: 1;
}
.modal {
	-webkit-overflow-scrolling: touch;
}
.modal-content {
	border: none;
	border-radius: 2px;
	box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.15);
}
.noUi-target,
.noUi-target * {
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-user-select: none;
	-ms-touch-action: none;
	touch-action: none;
	-ms-user-select: none;
	-moz-user-select: none;
	user-select: none;
	box-sizing: border-box;
}
.noUi-target {
	position: relative;
	direction: ltr;
}
.noUi-base,
.noUi-connects {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
}
.noUi-connects {
	overflow: hidden;
	z-index: 0;
}
.noUi-connect,
.noUi-origin {
	will-change: transform;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	transform-origin: 0 0;
}
html:not([dir='rtl']) .noUi-horizontal .noUi-origin {
	left: auto;
	right: 0;
}
.noUi-vertical .noUi-origin {
	width: 0;
}
.noUi-horizontal .noUi-origin {
	height: 0;
}
.noUi-handle {
	position: absolute;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
	transition: transform 0.3s;
}
.noUi-state-drag * {
	cursor: inherit !important;
}
.noUi-horizontal {
	height: 18px;
}
.noUi-horizontal .noUi-handle {
	width: 34px;
	height: 28px;
	left: -17px;
	top: -6px;
}
.noUi-vertical {
	width: 18px;
}
.noUi-vertical .noUi-handle {
	width: 28px;
	height: 34px;
	left: -6px;
	top: -17px;
}
html:not([dir='rtl']) .noUi-horizontal .noUi-handle {
	right: -17px;
	left: auto;
}
.noUi-target {
	height: 16px;
}
.noUi-base {
	padding-top: 5px;
}
.noUi-connects {
	background: #e0e0e0;
	border-radius: 3px;
	height: 6px;
}
.noUi-connect {
	border-radius: 2px;
	background: #1b68a4;
}
.noUi-horizontal .noUi-handle {
	cursor: ew-resize;
	width: 16px;
	height: 16px;
	border-radius: 8px;
	background: #3d464d;
	box-shadow: 0 0 0 0 rgba(61, 70, 77, 0);
	transition: box-shadow 0.2s;
}
.noUi-horizontal .noUi-handle:after {
	display: block;
	content: '';
	position: absolute;
	width: 6px;
	height: 6px;
	margin: 5px;
	border-radius: 3px;
	background: #1b68a4;
	transition: transform 0.2s;
}
.noUi-horizontal .noUi-handle:focus {
	outline: none;
	box-shadow: 0 0 0 3px rgba(61, 70, 77, 0.3);
}
.noUi-horizontal .noUi-handle:focus:after {
	transform: scale(0);
}
.noUi-horizontal .noUi-handle {
	top: 0;
}
html:not([dir='rtl']) .noUi-horizontal .noUi-handle {
	right: -8px;
}
.page-link {
	font-weight: 500;
	border-radius: 2px;
	border: none;
	line-height: 1;
	margin: 0 2px;
	padding: 9px 10px 8px;
	height: 33px;
	background: transparent;
	color: #3d464d;
}
.page-link:focus,
.page-link:hover {
	box-shadow: none;
	background: #ebebeb;
	color: #3d464d;
}
.page-link--with-arrow {
	width: 29px;
	position: relative;
}
.page-link--with-arrow .page-link__arrow {
	position: absolute;
	fill: currentColor;
	top: 10px;
}
.page-link--with-arrow .page-link__arrow--left {
	left: 9px;
}
.page-link--with-arrow .page-link__arrow--right {
	left: 11px;
}
.page-item:first-child .page-link {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}
.page-item:last-child .page-link {
	margin-right: 0;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}
.page-item.active {
	margin: 0 3px;
}
.page-item.active .page-link {
	background: #1b68a4;
	color: #e9e9e9;
}
.page-item.disabled .page-link {
	background: transparent;
	color: #b3b3b3;
}
.post-card__image {
	position: relative;
	overflow: hidden;
	border-radius: 1.5px;
}
.post-card__image img {
	max-width: 100%;
}
.post-card__image :before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.2);
	opacity: 0;
	transition: opacity 0.6s;
	z-index: 1;
}
.post-card__image:hover :before {
	opacity: 1;
}
.post-card__category {
	font-size: 14px;
	color: #adadad;
}
.post-card__category a {
	color: inherit;
	transition: 0.15s;
}
.post-card__category a:hover {
	color: #1b68a4;
}
.post-card__date {
	font-size: 14px;
	color: #adadad;
}
.post-card__name {
	font-size: 20px;
	line-height: 1.125;
	font-weight: 500;
}
.post-card__name a {
	color: inherit;
	transition: 0.15s;
}
.post-card__name a:hover {
	color: #1b68a4;
}
.post-card__content {
	font-size: 15px;
	line-height: 24px;
}
.post-card--layout--related .post-card__name {
	width: 100%;
	font-size: 17px;
	margin-top: 16px;
	line-height: 24px;
}
.post-card--layout--related .post-card__name a {
	transition: all 0.2s;
}
.post-card--layout--related .post-card__name a:hover {
	color: #1b68a4;
}
.post-card--layout--related .post-card__date {
	margin-top: 8px;
	font-size: 15px;
}
@media (max-width: 767px) {
	.post-card--layout--related .post-card__name {
		margin-top: 12px;
		font-size: 15px;
		line-height: 20px;
	}
	.post-card--layout--related .post-card__date {
		margin-top: 4px;
		font-size: 14px;
	}
}
.post-card--layout--grid .post-card__info {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.post-card--layout--grid .post-card__image {
	margin-bottom: 14px;
}
.post-card--layout--grid .post-card__category {
	display: none;
}
.post-card--layout--grid .post-card__date {
	-ms-flex-order: -1;
	order: -1;
}
.post-card--layout--grid .post-card__name {
	width: 100%;
	font-size: 18px;
	margin-top: 4px;
	line-height: 22px;
}
.post-card--layout--grid .post-card__content {
	width: 100%;
	margin-top: 12px;
}
.post-card--layout--grid .post-card__read-more {
	width: 100%;
	display: none;
}
.post-card--layout--grid.post-card--size--lg .post-card__image {
	margin-bottom: 0;
	border-radius: 3px;
}
.post-card--layout--grid.post-card--size--lg .post-card__info {
	padding: 28px 36px 12px;
}
.post-card--layout--grid.post-card--size--lg .post-card__category {
	font-size: 15px;
	-ms-flex-order: -2;
	order: -2;
	display: block;
}
.post-card--layout--grid.post-card--size--lg .post-card__date {
	padding-left: 22px;
	font-size: 15px;
	position: relative;
}
.post-card--layout--grid.post-card--size--lg .post-card__date:before {
	top: 9px;
	left: 9px;
	content: '';
	display: block;
	position: absolute;
	width: 4px;
	height: 4px;
	border-radius: 2px;
	background: currentColor;
}
.post-card--layout--grid.post-card--size--lg .post-card__name {
	margin-top: 8px;
	font-size: 24px;
	line-height: 32px;
}
.post-card--layout--grid.post-card--size--lg .post-card__content {
	margin-top: 20px;
	font-size: 16px;
}
.post-card--layout--grid.post-card--size--lg .post-card__read-more {
	display: block;
	margin-top: 18px;
}
@media (max-width: 767px) {
	.post-card--layout--grid.post-card--size--lg .post-card__info {
		padding: 20px 20px 12px;
	}
	.post-card--layout--grid.post-card--size--lg .post-card__name {
		font-size: 22px;
		line-height: 26px;
	}
	.post-card--layout--grid.post-card--size--lg .post-card__content {
		margin-top: 12px;
	}
}
@media (max-width: 479px) {
	.post-card--layout--grid.post-card--size--lg .post-card__info {
		padding: 12px;
	}
	.post-card--layout--grid.post-card--size--lg .post-card__name {
		font-size: 20px;
		line-height: 22px;
	}
	.post-card--layout--grid.post-card--size--lg .post-card__content {
		margin-top: 10px;
		font-size: 15px;
	}
}
@media (max-width: 379px) {
	.post-card--layout--grid.post-card--size--lg .post-card__name {
		margin-top: 4px;
	}
	.post-card--layout--grid.post-card--size--lg .post-card__info {
		padding: 10px 0;
	}
}
.post-card--layout--list {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-align: start;
	align-items: flex-start;
}
.post-card--layout--list.post-card--size--nl .post-card__info {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-top: 10px;
}
.post-card--layout--list.post-card--size--nl .post-card__image {
	width: 350px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-right: 30px;
}
.post-card--layout--list.post-card--size--nl .post-card__category {
	font-size: 15px;
	-ms-flex-order: -2;
	order: -2;
	display: block;
}
.post-card--layout--list.post-card--size--nl .post-card__date {
	-ms-flex-order: -1;
	order: -1;
	padding-left: 22px;
	font-size: 15px;
	position: relative;
}
.post-card--layout--list.post-card--size--nl .post-card__date:before {
	top: 9px;
	left: 9px;
	content: '';
	display: block;
	position: absolute;
	width: 4px;
	height: 4px;
	border-radius: 2px;
	background: currentColor;
}
.post-card--layout--list.post-card--size--nl .post-card__name {
	margin-top: 6px;
	font-size: 18px;
	line-height: 24px;
}
.post-card--layout--list.post-card--size--nl .post-card__content {
	margin-top: 20px;
	font-size: 16px;
}
.post-card--layout--list.post-card--size--nl .post-card__read-more {
	display: block;
	margin-top: 14px;
}
@media (min-width: 768px) and (max-width: 1199px) {
	.post-card--layout--list.post-card--size--nl .post-card__info {
		padding-top: 0;
	}
	.post-card--layout--list.post-card--size--nl .post-card__image {
		width: 260px;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		margin-right: 22px;
	}
	.post-card--layout--list.post-card--size--nl .post-card__content {
		margin-top: 8px;
		font-size: 16px;
		overflow: hidden;
		height: 48px;
	}
	.post-card--layout--list.post-card--size--nl .post-card__read-more {
		margin-top: 10px;
	}
}
@media (max-width: 767px) {
	.post-card--layout--list.post-card--size--nl {
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.post-card--layout--list.post-card--size--nl .post-card__image {
		width: auto;
		margin-right: 0;
	}
	.post-card--layout--list.post-card--size--nl .post-card__content {
		margin-top: 10px;
	}
}
.post-card--layout--list.post-card--size--sm .post-card__image {
	width: 240px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-right: 22px;
}
.post-card--layout--list.post-card--size--sm .post-card__name {
	margin-top: 10px;
	font-size: 16px;
	line-height: 20px;
	-ms-flex-order: -1;
	order: -1;
	height: 40px;
	overflow: hidden;
}
.post-card--layout--list.post-card--size--sm .post-card__date {
	margin-top: 6px;
}
.post-card--layout--list.post-card--size--sm .post-card__category {
	display: none;
}
.post-card--layout--list.post-card--size--sm .post-card__content {
	margin-top: 10px;
	font-size: 15px;
	line-height: 22px;
	height: 66px;
	overflow: hidden;
}
.post-card--layout--list.post-card--size--sm .post-card__read-more {
	display: none;
}
.product-card {
	position: relative;
	padding: 1px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	background: #fff;
}
.product-card:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	pointer-events: none;
	border-radius: 2px;
	box-shadow: inset 0 0 0 1px #ededed;
	transition: box-shadow 0.2s;
}
.product-card:hover:before {
	transition-duration: 0s;
	box-shadow: inset 0 0 0 2px #1b69a4d0;
}
.product-card:hover .product-card__quickview {
	transition-duration: 0s, 0s;
	background: #1b68a4;
}
.product-card:hover
	.product-card__quickview:not(.product-card__quickview--preload) {
	color: #fff;
}
.product-card:hover
	.product-card__quickview:not(.product-card__quickview--preload):hover {
	transition-duration: 0.2s, 0.2s;
	background: #3d464d;
	color: #fff;
}
.product-card__image {
	padding: 18px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.product-card__image a {
	display: block;
	padding-bottom: 100%;
	position: relative;
}
.product-card__image img {
	max-width: 100%;
	display: block;
	position: absolute;
}
.product-card__quickview {
	z-index: 2;
	position: absolute;
	border: none;
	border-radius: 0 2px 0 2px;
	font-size: 10px;
	text-transform: uppercase;
	fill: currentColor;
	padding: 7px;
	cursor: pointer;
	right: 0;
	top: 0;
	background: transparent;
	color: #ccc;
	transition: background-color 0.2s, color 0.2s;
}
.product-card__quickview .fake-svg-icon {
	display: none;
}
@supports (-ms-ime-align: auto) {
	.product-card__quickview svg {
		display: none;
	}
	.product-card__quickview .fake-svg-icon {
		display: block;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		width: 16px;
		height: 16px;
		font-size: 16px;
	}
	.product-card__quickview .fake-svg-icon:before {
		content: '\e900';
	}
}
.product-card__quickview:focus {
	outline: none;
}
.product-card__quickview--preload {
	color: transparent;
}
.product-card__quickview--preload:after {
	display: block;
	content: '';
	position: absolute;
	border: 2px solid rgba(0, 0, 0, 0.1);
	border-top-color: rgba(0, 0, 0, 0.5);
	transition: border-color 0.1s;
	animation-name: a;
	animation-duration: 0.5s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	left: calc(50% - 8px);
	top: calc(50% - 8px);
	width: 16px;
	height: 16px;
	border-radius: 8px;
}
.product-card__info {
	padding: 2px 22px 24px;
	-ms-flex-positive: 1;
	flex-grow: 1;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.product-card__name {
	font-size: 15px;
	line-height: 19px;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.product-card__name a {
	color: inherit;
	transition: 0.15s;
}
.product-card__name a:hover {
	color: #1b68a4;
}
.product-card__rating {
	margin-top: 6px;
	display: -ms-flexbox;
	display: flex;
}
.product-card__rating-legend {
	font-size: 13px;
	line-height: 1;
	color: #b3b3b3;
	margin-left: 9px;
	padding-top: 1px;
}
.product-card__prices {
	margin-top: 14px;
	line-height: 1;
	font-weight: 700;
	color: #3d464d;
}
.product-card__new-price {
	color: #ff2626;
}
.product-card__old-price {
	color: #b3b3b3;
	font-weight: 400;
	font-size: 13px;
	text-decoration: line-through;
}
.product-card__buttons {
	display: -ms-flexbox;
	display: flex;
	margin-top: 24px;
}
.product-card__buttons > :first-child {
	margin-right: auto;
}
.product-card__buttons > * + * {
	margin-left: 4px;
}
.product-card__addtocart--list {
	display: none;
}
.product-card__features-list {
	list-style: none;
	margin: 10px 0 6px;
	padding: 0;
	color: #6c757d;
	font-size: 13px;
	line-height: 15px;
}
.product-card__features-list li {
	padding: 3px 0 3px 12px;
	position: relative;
}
.product-card__features-list li:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 9px;
	width: 5px;
	height: 5px;
	border-radius: 2.5px;
	border: 1px solid currentColor;
}
.product-card__badges-list {
	left: 18px;
	top: 18px;
	position: absolute;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: start;
	align-items: flex-start;
	z-index: 1;
}
.product-card__badge {
	font-size: 11px;
	border-radius: 1.5px;
	letter-spacing: 0.02em;
	line-height: 1;
	padding: 5px 8px 4px;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 4px;
	margin-right: 4px;
}
.product-card__badge--sale {
	background: #f33;
	color: #fff;
}
.product-card__badge--new {
	background: #37f;
	color: #fff;
}
.product-card__badge--hot {
	background: #614ba6;
	color: #fff;
}
.product-card--layout--horizontal {
	-ms-flex-direction: row;
	flex-direction: row;
}
.product-card--layout--horizontal .product-card__image {
	width: 120px;
	padding: 16px;
}
.product-card--layout--horizontal .product-card__badges-list {
	left: 10px;
	top: 10px;
}
.product-card--layout--horizontal .product-card__badge {
	padding: 3px 5px 2px;
}
.product-card--layout--horizontal .product-card__info {
	padding: 14px 30px 42px 0;
	width: 100%;
}
.product-card--layout--horizontal .product-card__availability,
.product-card--layout--horizontal .product-card__buttons,
.product-card--layout--horizontal .product-card__description {
	display: none;
}
.product-card--layout--horizontal .product-card__actions {
	position: absolute;
	left: 120px;
	bottom: 18px;
}
.product-card--layout--horizontal .product-card__features-list {
	display: none;
}
.product-card--layout--grid .product-card__image {
	padding: 18px 18px 20px;
}
.product-card--layout--grid .product-card__info {
	padding: 0 24px;
}
.product-card--layout--grid .product-card__actions {
	padding: 0 24px 24px;
}
.product-card--layout--grid .product-card__availability,
.product-card--layout--grid .product-card__description,
.product-card--layout--grid .product-card__features-list {
	display: none;
}
.product-card--layout--grid.product-card--size--sm .product-card__image {
	padding: 15px;
}
.product-card--layout--grid.product-card--size--sm .product-card__badges-list {
	left: 16px;
	top: 16px;
}
.product-card--layout--grid.product-card--size--sm .product-card__info {
	padding: 0 15px;
}
.product-card--layout--grid.product-card--size--sm .product-card__actions {
	padding: 0 15px 15px;
}
.product-card--layout--grid.product-card--size--sm .product-card__buttons .btn {
	font-size: 0.8125rem;
	height: calc(1.5rem + 2px);
	line-height: 1.25;
	padding: 0.25rem 0.5625rem;
	font-weight: 500;
}
.product-card--layout--grid.product-card--size--sm
	.product-card__buttons
	.btn.btn-svg-icon {
	width: calc(1.5rem + 2px);
}
@media (hover: hover) {
	.product-card--layout--grid.product-card--size--sm .product-card__buttons {
		display: none;
	}
	.product-card--layout--grid.product-card--size--sm:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.product-card--layout--grid.product-card--size--sm:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
	.product-card--layout--grid.product-card--size--sm .product-card__buttons {
		display: none;
	}
	.product-card--layout--grid.product-card--size--sm:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.product-card--layout--grid.product-card--size--sm:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
.product-card--layout--grid.product-card--size--nl .product-card__buttons .btn {
	font-size: 0.875rem;
	height: calc(1.875rem + 2px);
	line-height: 1.25;
	padding: 0.375rem 1rem;
	font-weight: 500;
}
.product-card--layout--grid.product-card--size--nl
	.product-card__buttons
	.btn.btn-svg-icon {
	width: calc(1.875rem + 2px);
}
@media (hover: hover) {
	.product-card--layout--grid.product-card--size--nl .product-card__buttons {
		display: none;
	}
	.product-card--layout--grid.product-card--size--nl:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -56px;
	}
	.product-card--layout--grid.product-card--size--nl:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
	.product-card--layout--grid.product-card--size--nl .product-card__buttons {
		display: none;
	}
	.product-card--layout--grid.product-card--size--nl:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -56px;
	}
	.product-card--layout--grid.product-card--size--nl:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
.product-card--layout--grid.product-card--size--lg .product-card__image {
	padding: 25px;
}
.product-card--layout--grid.product-card--size--lg .product-card__info {
	padding: 0 30px;
	-ms-flex-positive: 0;
	flex-grow: 0;
}
.product-card--layout--grid.product-card--size--lg .product-card__name {
	font-size: 17px;
	line-height: 23px;
}
.product-card--layout--grid.product-card--size--lg .product-card__actions {
	padding: 0 30px 30px;
	-ms-flex-positive: 1;
	flex-grow: 1;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.product-card--layout--grid.product-card--size--lg .product-card__rating {
	margin-top: 10px;
}
.product-card--layout--grid.product-card--size--lg .product-card__prices {
	margin-bottom: auto;
	font-size: 20px;
}
.product-card--layout--list {
	-ms-flex-direction: row;
	flex-direction: row;
}
.product-card--layout--list .product-card__image {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	padding: 24px;
	width: 210px;
}
.product-card--layout--list .product-card__info {
	padding: 20px 24px 20px 4px;
}
.product-card--layout--list .product-card__name {
	font-size: 16px;
	line-height: 20px;
	-ms-flex-positive: 0;
	flex-grow: 0;
}
.product-card--layout--list .product-card__rating {
	margin-top: 7px;
}
.product-card--layout--list .product-card__description {
	color: #6c757d;
	font-size: 15px;
	line-height: 22px;
	margin-top: 12px;
}
.product-card--layout--list .product-card__actions {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 190px;
	padding: 16px 20px;
	border-left: 1px solid #ebebeb;
}
.product-card--layout--list .product-card__prices {
	margin-top: 16px;
	font-size: 18px;
}
.product-card--layout--list .product-card__old-price {
	font-size: 14px;
}
.product-card--layout--list .product-card__buttons {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.product-card--layout--list .product-card__addtocart {
	display: none;
}
.product-card--layout--list .product-card__addtocart--list {
	display: block;
}
.product-card--layout--list .product-card__addtocart {
	width: 100%;
}
.product-card--layout--list .product-card__addtocart + * {
	margin-left: 0;
}
.product-card--layout--list .product-card__addtocart ~ * {
	margin-top: 8px;
}
.product-card--layout--list .product-card__addtocart,
.product-card--layout--list .product-card__compare,
.product-card--layout--list .product-card__wishlist {
	font-size: 0.875rem;
	height: calc(1.875rem + 2px);
	line-height: 1.25;
	padding: 0.375rem 1rem;
	font-weight: 500;
}
.product-card--layout--list .product-card__addtocart.btn-svg-icon,
.product-card--layout--list .product-card__compare.btn-svg-icon,
.product-card--layout--list .product-card__wishlist.btn-svg-icon {
	width: calc(1.875rem + 2px);
}
.product-card--layout--list .product-card__availability {
	color: #999;
	font-size: 14px;
	padding-right: 10px;
}
.product-card--layout--list .product-card__features-list {
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 0;
}
.product-card--layout--list .product-card__features-list li {
	padding: 3px 0 3px 12px;
}
.product-card--layout--list .product-card__features-list li:before {
	top: 8px;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.product-card--layout--list .product-card__image {
		width: 180px;
		padding-left: 20px;
		padding-right: 20px;
	}
	.product-card--layout--list .product-card__info {
		padding-right: 20px;
		padding-left: 0;
	}
}
@media (max-width: 767px) {
	.product-card--layout--list {
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.product-card--layout--list .product-card__image {
		width: 250px;
		padding: 20px;
		margin: 0 auto;
	}
	.product-card--layout--list .product-card__info {
		border-top: 1px solid #ebebeb;
		padding: 20px;
	}
	.product-card--layout--list .product-card__actions {
		width: auto;
		border-left: none;
		border-top: 1px solid #ebebeb;
		padding: 20px;
	}
	.product-card--layout--list .product-card__buttons {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
	.product-card--layout--list .product-card__buttons .btn {
		font-size: 1rem;
		height: calc(2.25rem + 2px);
		line-height: 1.5;
		padding: 0.375rem 1.25rem;
		font-weight: 500;
	}
	.product-card--layout--list .product-card__buttons .btn.btn-svg-icon {
		width: calc(2.25rem + 2px);
	}
	.product-card--layout--list .product-card__addtocart--list {
		width: auto;
		margin-right: auto;
	}
}
.rating__body {
	display: -ms-flexbox;
	display: flex;
}
.rating__star + .rating__star {
	margin-left: 3px;
}
.rating__star:first-child + .rating__star--only-edge {
	margin-left: 0;
}
.rating__fill {
	color: #ccc;
	fill: currentColor;
}
.rating__stroke {
	color: rgba(0, 0, 0, 0.08);
	fill: currentColor;
}
.rating__star--active .rating__fill {
	color: #1b68a4;
}
.rating__star--active .rating__stroke {
	color: rgba(0, 0, 0, 0.08);
}
.rating__star--only-edge {
	position: relative;
	display: none;
}
@supports (-ms-ime-align: auto) {
	svg.rating__star {
		display: none;
	}
	.rating__star--only-edge {
		display: block;
	}
	.rating__fill .fake-svg-icon,
	.rating__stroke .fake-svg-icon {
		display: block;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		width: 13px;
		height: 12px;
		font-size: 12px;
	}
	.rating__fill .fake-svg-icon:before {
		content: '\e902';
	}
	.rating__stroke {
		position: absolute;
		left: 0;
		top: 0;
	}
	.rating__stroke .fake-svg-icon:before {
		content: '\e903';
	}
}
.share-links__list {
	display: -ms-flexbox;
	display: flex;
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 11px;
	line-height: 18px;
	font-weight: 500;
	color: #fff;
	white-space: nowrap;
}
.share-links__item + .share-links__item {
	margin-left: 4px;
}
.share-links__item a {
	border-radius: 1.5px;
	display: block;
	padding: 2px 6px 1px;
}
.share-links__item a,
.share-links__item a:hover {
	color: inherit;
	text-decoration: none;
}
.share-links__item--type--like a {
	background: #4267b2;
}
.share-links__item--type--like a:hover {
	background: #3b5c9f;
}
.share-links__item--type--tweet a {
	background: #1b95e0;
}
.share-links__item--type--tweet a:hover {
	background: #1886c9;
}
.share-links__item--type--pin a {
	background: #e63939;
}
.share-links__item--type--pin a:hover {
	background: #e32222;
}
.share-links__item--type--counter a {
	color: #737373;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
}
.share-links__item--type--counter a:hover {
	color: #666;
}
.tags__list {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	font-size: 13px;
	font-weight: 500;
	line-height: 18px;
	margin: -2px;
}
.tags__list a {
	display: block;
	background: transparent;
	border: 1px solid #e5e5e5;
	color: #3d464d;
	border-radius: 1.5px;
	padding: 3px 8px 2px;
	margin: 2px;
	transition: all 0.15s;
}
.tags__list a:focus,
.tags__list a:hover {
	background: #1b68a4;
	border: 1px solid #1b68a4;
	color: #ebebeb;
}
.tags--lg .tags__list {
	margin: -3px;
}
.tags--lg .tags__list a {
	padding: 6px 10px 5px;
	margin: 3px;
}
.tooltip {
	pointer-events: none;
	transition-duration: 0s;
	font-family: inherit;
}
.tooltip.show {
	opacity: 1;
}
.tooltip-inner {
	background: #3d464d;
	color: #fff;
	border-radius: 1.5px;
	position: relative;
	padding: 0.1875rem 0.625rem 0.125rem;
}
.tooltip .arrow {
	width: 8px;
	height: 8px;
}
.tooltip .arrow:before {
	width: 8px;
	height: 8px;
	border-radius: 1px;
	border: none;
	background: #3d464d;
	transform: rotate(45deg) scale(0.7619);
}
.bs-tooltip-auto[x-placement^='top'],
.bs-tooltip-top {
	padding: 4px 0;
}
.bs-tooltip-auto[x-placement^='top'] .arrow,
.bs-tooltip-auto[x-placement^='top'] .tooltip-inner,
.bs-tooltip-top .arrow,
.bs-tooltip-top .tooltip-inner {
	bottom: 3px;
}
.bs-tooltip-auto[x-placement^='bottom'],
.bs-tooltip-bottom {
	padding: 4px 0;
}
.bs-tooltip-auto[x-placement^='bottom'] .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .tooltip-inner,
.bs-tooltip-bottom .arrow,
.bs-tooltip-bottom .tooltip-inner {
	top: 3px;
}
.bs-tooltip-auto[x-placement^='right'],
.bs-tooltip-right {
	padding: 0 4px;
}
.bs-tooltip-auto[x-placement^='right'] .arrow,
.bs-tooltip-auto[x-placement^='right'] .tooltip-inner,
.bs-tooltip-right .arrow,
.bs-tooltip-right .tooltip-inner {
	left: 3px;
}
.bs-tooltip-auto[x-placement^='left'],
.bs-tooltip-left {
	padding: 0 4px;
}
.bs-tooltip-auto[x-placement^='left'] .arrow,
.bs-tooltip-auto[x-placement^='left'] .tooltip-inner,
.bs-tooltip-left .arrow,
.bs-tooltip-left .tooltip-inner {
	right: 3px;
}
.typography {
	line-height: 1.625;
}
.typography a:hover {
	text-decoration: underline;
}
.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
	margin-top: 1.75em;
	margin-bottom: 0.75em;
}
.typography p {
	margin-bottom: 1.5em;
}
.typography ol,
.typography ul {
	margin: 1.7em 0;
}
.typography li {
	margin-bottom: 0.7em;
}
.typography > :first-child {
	margin-top: 0;
}
.typography > :last-child {
	margin-bottom: 0;
}
.typography a > img {
	transition: opacity 0.2s;
}
.typography a:hover > img {
	opacity: 0.8;
}
.typography img {
	max-width: 100%;
}
.typography figure {
	margin: 2.5em 0;
}
.typography figcaption {
	margin-top: 20px;
	text-align: center;
	font-size: 14px;
	color: #999;
}
.typography hr {
	position: relative;
	width: 4px;
	height: 4px;
	border-radius: 2px;
	border: none;
	background: #ccc;
	margin: 2.5em auto;
}
.typography hr:after,
.typography hr:before {
	position: absolute;
	content: '';
	display: block;
	width: inherit;
	height: inherit;
	border-radius: inherit;
	background: inherit;
}
.typography hr:after {
	left: -30px;
}
.typography hr:before {
	right: -30px;
}
.typography blockquote {
	margin: 3em 2.5em 2.5em;
	text-align: center;
	font-size: 18px;
	font-style: italic;
}
.typography blockquote cite {
	margin-top: -0.5em;
	color: #999;
	display: block;
	font-size: 15px;
	font-style: normal;
}
.typography blockquote p {
	margin-bottom: 1.25em;
}
.typography blockquote > :first-child {
	margin-top: 0;
}
.typography blockquote > :last-child {
	margin-bottom: 0;
}
@media (min-width: 576px) and (max-width: 767px) {
	.typography blockquote {
		margin: 2.5em 1.5em 2em;
	}
}
@media (max-width: 575px) {
	.typography blockquote {
		font-size: 16px;
		text-align: left;
		margin: 2.75em 0 2.5em;
	}
}
@media (min-width: 992px) {
	.typography--expanded figure {
		text-align: center;
		position: relative;
		left: -95px;
		width: calc(100% + 190px);
		margin: 3em 0;
	}
	.typography--expanded figure img {
		overflow: hidden;
		border-radius: 3px;
	}
}
.filter__title {
	display: block;
	border: none;
	padding: 6px 10px;
	cursor: pointer;
	width: calc(100% + 20px);
	position: relative;
	text-align: left;
	color: inherit;
	font-weight: 700;
	font-size: 15px;
	line-height: 18px;
	border-radius: 2px;
	margin: 0 -10px;
	transition: background-color 0.2s, fill 0.2s;
	background: transparent;
	fill: #ccc;
}
.filter__title:focus,
.filter__title:hover {
	transition-duration: 0.1s, 0.1s;
	background: #f2f2f2;
	fill: #b3b3b3;
}
.filter__title:focus {
	outline: none;
}
.filter__arrow {
	position: absolute;
	top: 11px;
	right: 10px;
	transition: transform 0.2s;
}
.filter__body {
	margin: 0 -20px;
	overflow: hidden;
	visibility: hidden;
	height: 0;
	opacity: 0;
	transition: height 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
.filter__container {
	padding: 10px 20px 16px;
}
.filter--opened .filter__body {
	transition-delay: 0s, 0s;
	visibility: visible;
	height: auto;
	opacity: 1;
}
.filter--opened .filter__arrow {
	transform: rotate(180deg);
}
.filter-categories__list {
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 15px;
	line-height: 18px;
}
.filter-categories__list a {
	color: inherit;
	transition: color 0.15s;
}
.filter-categories__list a:hover {
	color: #1b68a4;
}
.filter-categories__item {
	padding: 6px 0;
	display: -ms-flexbox;
	display: flex;
}
.filter-categories__item > a {
	padding-right: 10px;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.filter-categories__item--parent {
	position: relative;
	padding-left: 14px;
}
.filter-categories__item--current {
	font-weight: 700;
}
.filter-categories__item--child {
	position: relative;
	padding-left: 14px;
	font-size: 15px;
}
.filter-categories__arrow {
	fill: #ccc;
	position: absolute;
	left: 0;
	top: 10px;
}
.filter-categories__counter {
	font-size: 12px;
	line-height: 12px;
	padding-top: 4px;
	font-weight: 400;
	color: #999;
}
.filter-color__list {
	padding: 2px 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: -4px;
}
.filter-color__item {
	cursor: pointer;
	margin: 4px;
}
.filter-color__check {
	display: block;
}
.filter-list__list {
	margin: -8px 0;
}
.filter-list__item {
	padding: 1px 0;
	cursor: pointer;
	display: -ms-flexbox;
	display: flex;
	margin: 8px 0;
}
.filter-list__input {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-right: 7px;
}
.filter-list__title {
	padding: 1px 0;
	font-size: 14px;
	line-height: 16px;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.filter-list__counter {
	margin-left: 12px;
	padding-top: 3px;
	font-size: 12px;
	line-height: 12px;
	color: #999;
}
.filter-list__item--disabled {
	cursor: default;
}
.filter-list__item--disabled .filter-list__title {
	color: #999;
}
.filter-price__slider {
	margin-top: 6px;
}
.filter-price__title {
	margin-top: 16px;
	font-size: 14px;
	color: #999;
}
.departments {
	width: 255px;
	height: 42px;
	color: #1b68a4;
	position: relative;
}
.departments__button {
	text-align: left;
	border: none;
	background: transparent;
	color: inherit;
	font-size: 15px;
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: default;
	font-weight: 500;
	padding: 0 32px 0 47px;
}
.departments__button:focus {
	outline: none;
}
.departments__button-icon {
	position: absolute;
	left: 16px;
	fill: #859ba6;
	transition: fill 0.2s;
}
.departments__button-arrow {
	position: absolute;
	right: 13px;
	top: calc(50% - 3px);
	fill: #859ba6;
	transition: fill 0.2s, transform 0.2s;
}
.departments__body {
	width: 100%;
	padding-top: 42px;
	position: absolute;
	background: #f0f5f9;
	box-shadow: none;
	border-radius: 2px;
}
.departments__links {
	position: relative;
	list-style: none;
	padding: 0 0 14px;
	margin: 6px 0 0;
}
.departments__links > li:hover .departments__megamenu,
.departments__links > li:hover .departments__menu {
	visibility: visible;
}
.departments__links > li:hover > a {
	background: #1b68a41f;
}
.departments__links > li > a {
	font-weight: 400;
	position: relative;
	display: block;
	padding: 10px 16px;
	color: inherit;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.02em;
}
.departments__item--menu {
	position: relative;
}
.departments__link-arrow {
	right: 14px;
	top: calc(50% - 5px);
	position: absolute;
	fill: #98aab3;
}
.departments__megamenu,
.departments__menu {
	position: absolute;
	left: 100%;
	top: 0;
	height: 100%;
	visibility: hidden;
}
.departments__megamenu--sm {
	width: 237px;
}
.departments__megamenu--nl {
	width: 443px;
}
.departments__megamenu--lg {
	width: 649px;
}
.departments__megamenu--xl {
	width: 855px;
}
.departments__links-wrapper {
	overflow: hidden;
	height: 0;
	opacity: 0;
	transition: all 0.3s ease-in-out;
}
.departments:not(.departments--fixed) .departments__button {
	cursor: pointer;
}
.departments:not(.departments--fixed)
	.departments__button:hover
	.departments__button-arrow,
.departments:not(.departments--fixed)
	.departments__button:hover
	.departments__button-icon {
	fill: currentColor;
}
.departments--opened .departments__links-wrapper {
	overflow: visible;
	height: auto;
	opacity: 1;
}
.departments--opened .departments__button-arrow {
	transform: rotate(180deg);
}
.departments--transition .departments__links-wrapper {
	overflow: hidden;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.departments {
		width: 210px;
	}
	.departments__megamenu--sm {
		width: 204px;
	}
	.departments__megamenu--nl {
		width: 376px;
	}
	.departments__megamenu--lg {
		width: 548px;
	}
	.departments__megamenu--xl {
		width: 720px;
	}
}
.drop-search {
	color: #3d464d;
	background: #fff;
	box-shadow: 0 1px 15px rgba(0, 0, 0, 0.25);
}
.drop-search__form {
	display: -ms-flexbox;
	display: flex;
	height: 50px;
	width: 320px;
}
.drop-search__button,
.drop-search__input {
	border: none;
	padding: 0;
	background: transparent;
}
.drop-search__button:focus,
.drop-search__input:focus {
	outline: none;
}
.drop-search__input {
	padding: 0 0 0 18px;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	width: 0;
}
.drop-search__button {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	cursor: pointer;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 52px;
	fill: #bfbfbf;
	transition: fill 0.2s;
}
.drop-search__button:hover {
	fill: #3d464d;
}
.dropcart {
	font-size: 15px;
	width: 320px;
	font-weight: 400;
	color: #3d464d;
	background: #fff;
	box-shadow: 0 1px 15px rgba(0, 0, 0, 0.25);
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
	.dropcart {
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
	}
}
.dropcart__products-list {
	padding: 20px 16px;
}
.dropcart__product {
	display: -ms-flexbox;
	display: flex;
}
.dropcart__product + .dropcart__product {
	margin-top: 24px;
}
.dropcart__product-image {
	width: 70px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.dropcart__product-image img {
	max-width: 100%;
}
.dropcart__product-name {
	margin-top: -2px;
	line-height: 18px;
	font-size: 15px;
}
.dropcart__product-name a {
	color: inherit;
	transition: 0.15s;
}
.dropcart__product-name a:hover {
	color: #1b68a4;
}
.dropcart__product-info {
	margin: 0 10px 0 16px;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.dropcart__product-options {
	list-style: none;
	margin: 3px 0 0;
	padding: 0;
	font-size: 13px;
	line-height: 16px;
	color: #999;
}
.dropcart__product-meta {
	margin-top: 5px;
	font-size: 13px;
}
.dropcart__product-price {
	font-size: 14px;
	font-weight: 700;
}
.dropcart__product-remove {
	margin-right: -6px;
	margin-top: -6px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.dropcart__totals {
	border-top: 1px solid #ebebeb;
	line-height: 24px;
	padding: 16px 20px 0;
}
.dropcart__totals th {
	padding-right: 16px;
}
.dropcart__totals td {
	text-align: right;
}
.dropcart__totals table {
	width: 100%;
}
.dropcart__buttons {
	display: -ms-flexbox;
	display: flex;
	margin-top: 4px;
	padding: 20px;
}
.dropcart__buttons > * {
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.dropcart__buttons > :first-child {
	margin-right: 8px;
}
.indicator {
	color: none;
}
.indicator,
.indicator__button {
	display: -ms-flexbox;
	display: flex;
}
.indicator__button {
	-ms-flex-align: center;
	align-items: center;
	border: none;
	background: transparent;
	cursor: pointer;
	padding: 0;
	color: inherit;
	fill: currentColor;
}
.indicator__button:focus {
	outline: none;
}
.indicator__button:hover {
	color: inherit;
}
.indicator__area {
	background: transparent;
	transition: background-color 0.2s;
	border-radius: 2px;
	height: 42px;
	padding: 0 14px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
.indicator__value {
	height: 15px;
	font-size: 10px;
	padding: 0 5px;
	border-radius: 1000px;
	position: relative;
	top: -11px;
	margin-left: -5px;
	margin-right: -8px;
	background: #fff;
	color: #3d464d;
	font-weight: 700;
}
.indicator__dropdown {
	top: 100%;
	right: 0;
	position: absolute;
	visibility: hidden;
	transform-origin: top;
	transform: rotateX(60deg);
	opacity: 0;
	transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
.indicator--trigger--click.indicator--opened .indicator__button,
.indicator:hover .indicator__button {
	color: none;
}
.indicator--trigger--click.indicator--opened .indicator__area,
.indicator:hover .indicator__area {
	background: hsla(0, 0%, 100%, 0.5);
	transition-duration: 0.1s;
}
.indicator--trigger--click.indicator--opened .indicator__value,
.indicator:hover .indicator__value {
	background: #fff;
	color: #3d464d;
}
.indicator--trigger--click.indicator--opened .indicator__dropdown,
.indicator--trigger--hover:hover .indicator__dropdown {
	z-index: 1;
	transition-delay: 0s, 0s, 0s;
	opacity: 1;
	visibility: visible;
	transform: rotateX(0deg);
}
.indicator__icon {
	transition: transform 0.2s, opacity 0.2s;
}
.indicator__icon--open {
	position: absolute;
}
.indicator--trigger--click.indicator--opened
	.indicator__icon:not(.indicator__icon--open),
.indicator--trigger--click:not(.indicator--opened) .indicator__icon--open {
	transform: scale(0);
	opacity: 0;
}
.indicator--mobile .indicator__area {
	height: 36px;
	padding: 0 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.indicator__area {
		padding: 0 12px;
	}
}
.megamenu {
	background: #fff;
	color: #3d464d;
	box-shadow: 0 1px 15px rgba(0, 0, 0, 0.25);
	min-height: 100%;
	width: 100%;
	padding: 26px 30px;
	line-height: 1.5;
	letter-spacing: 0.01em;
	background-position: 100% 100%;
	background-repeat: no-repeat;
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
	.megamenu {
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
	}
}
.megamenu--departments {
	padding-right: 31px;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.megamenu--departments {
		padding-right: 32px;
	}
}
.megamenu__links {
	list-style: none;
	margin: 0;
	padding: 0;
}
.megamenu__links a {
	color: inherit;
	transition: color 0.15s;
}
.megamenu__links a:hover {
	color: #1b68a4;
}
.megamenu__links--level--0 > .megamenu__item + .megamenu__item--with-submenu,
.megamenu__links--level--0 > .megamenu__item--with-submenu + .megamenu__item {
	margin-top: 20px;
}
.megamenu__links--level--0 > .megamenu__item {
	padding: 1px 0;
}
.megamenu__links--level--0 > .megamenu__item > a {
	font-weight: 500;
}
.megamenu__links--level--0 > .megamenu__item > a + .megamenu__links {
	padding-top: 10px;
	margin-top: 3px;
	border-top: 1px solid #e5e5e5;
}
.megamenu__links--level--1 > .megamenu__item > a {
	font-size: 14px;
	color: #5c6b73;
	transition: color 0.15s;
}
.megamenu__links--level--1 > .megamenu__item > a:hover {
	color: #1b68a4;
}
.menu {
	color: #3d464d;
	background: #fff;
	box-shadow: 0 1px 15px rgba(0, 0, 0, 0.25);
	list-style: none;
	width: 190px;
	padding: 10px 0;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.02em;
	font-weight: 500;
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
	.menu {
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
	}
}
.menu > li {
	position: relative;
}
.menu > li > a {
	display: block;
	padding: 8px 24px 6px 16px;
	color: inherit;
}
.menu > li:hover > a {
	background: #f2f2f2;
}
.menu > li:hover .menu__submenu {
	opacity: 1;
	visibility: visible;
	transform: rotateY(0deg);
}
.menu__icon {
	position: absolute;
	top: 3px;
	left: 15px;
}
.menu__arrow {
	position: absolute;
	right: 12px;
	top: calc(50% - 5px);
	fill: #b3b3b3;
}
.menu__submenu {
	top: -10px;
	left: 100%;
	position: absolute;
	visibility: hidden;
	transform-origin: left;
	transform: rotateY(45deg);
	opacity: 0;
	transition: transform 0.2s, opacity 0.2s;
}
.menu--with-icons > li > a {
	padding-left: 50px;
}
.menu--layout--topbar {
	padding: 6px 0;
	width: 150px;
	box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
	.menu--layout--topbar {
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
	}
}
.menu--layout--topbar > li > a {
	display: block;
	padding: 8px 21px 6px 13px;
	color: inherit;
}
.menu--layout--topbar.menu--with-icons .menu__icon {
	position: absolute;
	top: 3px;
	left: 12px;
}
.menu--layout--topbar.menu--with-icons > li > a {
	padding-left: 45px;
}
.mobile-header {
	height: 54px;
}
.mobile-header__panel {
	background: #1b68a4;
	box-shadow: 0 1px rgba(0, 0, 0, 0.04), inset 0 -1px rgba(0, 0, 0, 0.04);
	color: #fcfcfc;
	position: relative;
}
.mobile-header__body {
	height: 54px;
}
.mobile-header__body,
.mobile-header__menu-button {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
.mobile-header__menu-button {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 36px;
	height: 36px;
	border-radius: 2px;
	background: transparent;
	fill: #3d464d;
	border: none;
	padding: 0;
	margin: 0;
	color: #fcfcfc;
	cursor: pointer;
	transition: fill 0.2s, background 0.2s;
	-ms-flex-pack: center;
	justify-content: center;
}
.mobile-header__menu-button:focus {
	outline: none;
}
.mobile-header__menu-button:focus,
.mobile-header__menu-button:hover {
	background: hsla(0, 0%, 100%, 0.5);
	fill: #3d464d;
}
.mobile-header__indicators {
	display: -ms-flexbox;
	display: flex;
	margin-left: auto;
}
.mobile-header__logo {
	margin: 0 16px 0 12px;
	color: inherit;
	fill: currentColor;
}
.mobile-header__logo:hover {
	color: inherit;
}
.mobile-header__logo svg {
	display: block;
}
@media (min-width: 576px) and (max-width: 991px) {
	.mobile-header__search {
		-ms-flex-positive: 1;
		flex-grow: 1;
		margin-right: 8px;
		height: 36px;
		position: relative;
	}
	.mobile-header__search input {
		position: relative;
		z-index: 1;
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		width: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		background: transparent;
		border: none;
		padding: 0 12px;
		margin: 0;
		font-family: inherit;
		font-size: 15px;
		color: inherit;
	}
	.mobile-header__search input::-webkit-input-placeholder {
		color: rgba(0, 0, 0, 0.7);
	}
	.mobile-header__search input:-ms-input-placeholder,
	.mobile-header__search input::-ms-input-placeholder {
		color: rgba(0, 0, 0, 0.7);
	}
	.mobile-header__search input::placeholder {
		color: rgba(0, 0, 0, 0.7);
	}
	.mobile-header__search input:hover {
		color: inherit;
	}
	.mobile-header__search input:hover::-webkit-input-placeholder {
		color: rgba(0, 0, 0, 0.7);
	}
	.mobile-header__search input:hover:-ms-input-placeholder,
	.mobile-header__search input:hover::-ms-input-placeholder {
		color: rgba(0, 0, 0, 0.7);
	}
	.mobile-header__search input:hover::placeholder {
		color: rgba(0, 0, 0, 0.7);
	}
	.mobile-header__search input:focus {
		color: inherit;
		outline: none;
	}
	.mobile-header__search input:focus::-webkit-input-placeholder {
		color: #999;
	}
	.mobile-header__search input:focus:-ms-input-placeholder,
	.mobile-header__search input:focus::-ms-input-placeholder {
		color: #999;
	}
	.mobile-header__search input:focus::placeholder {
		color: #999;
	}
	.mobile-header__search input:hover ~ .mobile-header__search-button {
		fill: rgba(0, 0, 0, 0.3);
	}
	.mobile-header__search input:hover ~ .mobile-header__search-button:focus,
	.mobile-header__search input:hover ~ .mobile-header__search-button:hover {
		fill: #3d464d;
	}
	.mobile-header__search input:hover ~ div {
		background: hsla(0, 0%, 100%, 0.8);
		box-shadow: none;
	}
	.mobile-header__search input:focus ~ .mobile-header__search-button {
		fill: rgba(0, 0, 0, 0.3);
	}
	.mobile-header__search input:focus ~ .mobile-header__search-button:focus,
	.mobile-header__search input:focus ~ .mobile-header__search-button:hover {
		fill: #3d464d;
	}
	.mobile-header__search input:focus ~ div {
		background: #fff;
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
	}
	.mobile-header__search div {
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: hsla(0, 0%, 100%, 0.5);
		box-shadow: none;
		transition: all 0.2s;
		border-radius: 2px;
	}
	.mobile-header__search-form {
		height: 100%;
		display: -ms-flexbox;
		display: flex;
	}
	.mobile-header__search-button {
		position: relative;
		z-index: 1;
		background: transparent;
		border: none;
		padding: 0;
		margin: 0;
		width: 36px;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		cursor: pointer;
		fill: rgba(0, 0, 0, 0.3);
		transition: fill 0.2s;
	}
	.mobile-header__search-button:focus {
		outline: none;
	}
	.mobile-header__search-button:focus,
	.mobile-header__search-button:hover {
		fill: #3d464d;
	}
	.mobile-header__search-button--close {
		display: none;
	}
}
@media (max-width: 575px) {
	.mobile-header__search {
		left: 0;
		top: 0;
		width: 100%;
		position: absolute;
		background: #fff;
		box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
		z-index: 1;
		transform: translateY(-100%);
		visibility: hidden;
		opacity: 0;
		transition: transform 0.3s, visibility 0s 0.3s, opacity 0.3s;
	}
	.mobile-header__search form {
		height: 54px;
		display: -ms-flexbox;
		display: flex;
	}
	.mobile-header__search input {
		-ms-flex-positive: 1;
		flex-grow: 1;
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		width: 0;
		background: transparent;
		border: none;
		padding: 0 0 0 15px;
	}
	.mobile-header__search input:focus {
		outline: none;
	}
	.mobile-header__search input::-webkit-input-placeholder {
		color: #999;
	}
	.mobile-header__search input:-ms-input-placeholder,
	.mobile-header__search input::-ms-input-placeholder {
		color: #999;
	}
	.mobile-header__search input::placeholder {
		color: #999;
	}
	.mobile-header__search--opened {
		transition-delay: 0s, 0s;
		transform: translateY(0);
		visibility: visible;
		opacity: 1;
	}
	.mobile-header__search-button {
		-ms-flex-negative: 0;
		flex-shrink: 0;
		background: transparent;
		border: none;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		width: 54px;
		cursor: pointer;
		fill: #b3b3b3;
	}
	.mobile-header__search-button:focus {
		outline: none;
	}
	.mobile-header__search-button:focus,
	.mobile-header__search-button:hover {
		fill: #3d464d;
	}
	.mobile-header__search-button--close {
		border-left: 1px solid #ebebeb;
	}
}
.mobile-links {
	list-style: none;
	padding: 0;
	margin: 0;
}
.mobile-links__item {
	border-bottom: 1px solid #ebebeb;
}
.mobile-links__item-title {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: stretch;
	align-items: stretch;
	cursor: pointer;
}
.mobile-links__item-link {
	-ms-flex-positive: 1;
	flex-grow: 1;
	color: #3d464d;
}
.mobile-links__item-link,
.mobile-links__item-link:hover {
	color: inherit;
}
.mobile-links__item-toggle {
	border: none;
	border-left: 1px solid #ebebeb;
	background: transparent;
	padding: 0;
	width: 54px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	cursor: inherit;
	fill: #999;
	transition: fill 0.2s;
}
.mobile-links__item-toggle:focus {
	outline: none;
}
.mobile-links__item-toggle:focus,
.mobile-links__item-toggle:hover {
	cursor: inherit;
}
.mobile-links__item-arrow {
	transition: transform 0.3s;
}
.mobile-links__item-sub-links {
	visibility: hidden;
	overflow: hidden;
	height: 0;
	opacity: 0;
	transition: height 0.3s, opacity 0.3s, visibility 0s 0.3s;
}
.mobile-links__item--open > .mobile-links__item-sub-links {
	visibility: visible;
	height: auto;
	opacity: 1;
	transition-delay: 0s, 0s, 0s;
}
.mobile-links__item--open
	> .mobile-links__item-title
	.mobile-links__item-arrow {
	transform: rotate(180deg) translateY(1px);
}
.mobile-links--level--0 > .mobile-links__item {
	border-bottom: 1px solid #ebebeb;
}
.mobile-links--level--0 > .mobile-links__item > .mobile-links__item-title {
	height: 48px;
}
.mobile-links--level--0
	> .mobile-links__item
	> .mobile-links__item-title
	.mobile-links__item-link {
	padding: 12px 20px;
}
.mobile-links:not(.mobile-links--level--0) {
	border-top: 1px solid #ebebeb;
}
.mobile-links--level--1 {
	background: #f7f7f7;
	font-size: 14px;
	line-height: 14px;
}
.mobile-links--level--1 .mobile-links__item .mobile-links__item-link {
	padding: 13px 20px;
}
.mobile-links--level--1 .mobile-links {
	padding-left: 20px;
	background: #fff;
}
.mobile-links--level--1 .mobile-links > li {
	background: #f7f7f7;
	border-left: 1px solid #ebebeb;
}
.mobile-links--level--1 .mobile-links__item:last-child {
	border-bottom: none;
}
.mobilemenu {
	visibility: hidden;
	position: fixed;
	z-index: 1000;
	transition: visibility 0s 0.3s;
}
.mobilemenu,
.mobilemenu__backdrop {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.mobilemenu__backdrop {
	position: absolute;
	background: #3d464d;
	opacity: 0;
	transition: opacity 0.3s;
}
.mobilemenu__body {
	position: absolute;
	left: 0;
	top: 0;
	width: 290px;
	height: 100%;
	background: #fff;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	transform: translateX(calc(-100% - 30px));
	transition: transform 0.3s;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.mobilemenu__header {
	height: 55px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid #ebebeb;
}
.mobilemenu__title {
	padding: 0 20px;
	font-weight: 700;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.mobilemenu__close {
	border: none;
	border-left: 1px solid #ebebeb;
	background: transparent;
	height: 54px;
	width: 54px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	cursor: pointer;
	fill: #999;
	transition: fill 0.2s;
}
.mobilemenu__close:focus {
	outline: none;
}
.mobilemenu__close:focus,
.mobilemenu__close:hover {
	fill: #3d464d;
}
.mobilemenu__content {
	-ms-flex-positive: 1;
	flex-grow: 1;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}
.mobilemenu--open {
	visibility: visible;
	transition-delay: 0s;
}
.mobilemenu--open .mobilemenu__backdrop {
	opacity: 0.9;
}
.mobilemenu--open .mobilemenu__body {
	transform: translateX(0);
}
.nav-links {
	height: 100%;
}
.nav-links__list {
	height: inherit;
	z-index: 10;
	display: -ms-flexbox;
	display: flex;
	list-style: none;
	padding: 0;
	margin: 0;
}
.nav-links__item {
	height: 100%;
}
.nav-links__item > a {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	font-size: 15px;
	line-height: 1;
	letter-spacing: 0.02em;
	font-weight: 700;
	color: inherit;
}
.nav-links__item > a span {
	background: transparent;
	box-shadow: none;
}
.nav-links__item > a svg {
	fill: rgba(0, 0, 0, 0.3);
}
.nav-links__item > a > span {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	background: transparent;
	transition: background-color 0.2s;
	padding: 0 14px;
	height: 42px;
	border-radius: 2px;
}
.nav-links__item:hover > a {
	color: inherit;
}
.nav-links__item:hover > a span {
	background: #ffffffe6;
	box-shadow: none;
	color: #1b68a4;
}
.nav-links__item:hover > a svg {
	fill: rgba(0, 0, 0, 0.3);
}
.nav-links__item:hover > a > span {
	transition-duration: 0.1s;
}
.nav-links__item:hover .nav-links__megamenu,
.nav-links__item:hover .nav-links__menu {
	opacity: 1;
	visibility: visible;
	transform: rotateX(0deg);
}
.nav-links__item--menu {
	position: relative;
}
.nav-links__item--with-submenu > a span {
	position: relative;
	padding-right: 27px;
}
.nav-links__item--with-submenu > a svg {
	right: 12px;
	top: calc(50% - 3px);
	position: absolute;
}
.nav-links__megamenu,
.nav-links__menu {
	top: 100%;
	position: absolute;
	visibility: hidden;
	transform-origin: top;
	transform: rotateX(45deg);
	opacity: 0;
	transition: transform 0.2s, opacity 0.2s;
}
.nav-links__megamenu {
	left: 0;
}
.nav-links__megamenu--size--xs {
	width: 16.66667%;
}
.nav-links__megamenu--size--sm {
	width: 33.33333%;
}
.nav-links__megamenu--size--nl {
	width: 50%;
}
.nav-links__megamenu--size--md {
	width: 66.66667%;
}
.nav-links__megamenu--size--lg {
	width: 83.33333%;
}
.nav-links__megamenu--size--xl {
	width: 100%;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.nav-links__item > a > span {
		padding: 0 10px;
	}
	.nav-links__item--with-submenu > a > span {
		padding-right: 23px;
	}
	.nav-links__item--with-submenu > a svg {
		right: 8px;
	}
}
.nav-panel {
	height: 54px;
	background: #1b68a4;
	box-shadow: 0 1px rgba(0, 0, 0, 0.04), inset 0 -1px rgba(0, 0, 0, 0.04);
	color: #f3f3f3;
}
.nav-panel--stuck {
	z-index: 10;
	width: 100%;
	position: fixed;
	top: 0;
	box-shadow: 0 1px rgba(0, 0, 0, 0.04), inset 0 -1px rgba(0, 0, 0, 0.04),
		0 1px 20px rgba(0, 0, 0, 0.15);
}
.nav-panel__container {
	height: 100%;
}
.nav-panel__row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	height: 100%;
}
.nav-panel__logo svg {
	display: block;
	fill: #3d464d;
}
.nav-panel__departments {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.nav-panel__departments + .nav-panel__nav-links,
.nav-panel__logo + .nav-panel__nav-links {
	margin-left: 18px;
}
.nav-panel__nav-links {
	margin-right: 18px;
}
.nav-panel__indicators {
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	margin-left: auto;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.nav-panel__nav-links {
		margin-left: 14px;
		margin-right: 14px;
	}
}
.search__form {
	position: relative;
	height: 42px;
	display: -ms-flexbox;
	display: flex;
}
.search__border {
	z-index: -1;
	position: absolute;
	pointer-events: none;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 3px;
	transition: box-shadow 0.15s, background 0.15s;
}
.search__input {
	border: none;
	padding: 0 17px;
	background: transparent;
	width: 1px;
	-ms-flex-positive: 1;
	flex-grow: 1;
	font-size: 15px;
	transition: color 0.15s;
	color: inherit;
}
.search__input::-webkit-input-placeholder {
	color: #999;
}
.search__input:-ms-input-placeholder,
.search__input::-ms-input-placeholder {
	color: #999;
}
.search__input::placeholder {
	color: #999;
}
.search__input ~ .search__border {
	background: transparent;
	box-shadow: inset 0 0 0 2px #e5e5e5;
}
.search__input:hover {
	color: inherit;
}
.search__input:hover::-webkit-input-placeholder {
	color: #999;
}
.search__input:hover:-ms-input-placeholder,
.search__input:hover::-ms-input-placeholder {
	color: #999;
}
.search__input:hover::placeholder {
	color: #999;
}
.search__input:hover ~ .search__border {
	background: transparent;
	box-shadow: inset 0 0 0 2px #d4d4d4;
}
.search__input:hover ~ .search__button {
	fill: #bfbfbf;
}
.search__input:hover ~ .search__button:hover {
	fill: #1b68a4;
}
.search__input:focus {
	outline: none;
	color: inherit;
}
.search__input:focus::-webkit-input-placeholder {
	color: #999;
}
.search__input:focus:-ms-input-placeholder,
.search__input:focus::-ms-input-placeholder {
	color: #999;
}
.search__input:focus::placeholder {
	color: #999;
}
.search__input:focus ~ .search__border {
	background: transparent;
	box-shadow: inset 0 0 0 2px #1b68a4;
}
.search__input:focus ~ .search__button {
	fill: #bfbfbf;
}
.search__input:focus ~ .search__button:hover {
	fill: #1b68a4;
}
.search__button {
	border: none;
	padding: 0;
	background: transparent;
	-ms-flex-positive: 0;
	flex-grow: 0;
	width: 42px;
	cursor: pointer;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	fill: #bfbfbf;
	transition: fill 0.15s;
}
.search__button:focus,
.search__button:hover {
	outline: none;
	fill: #1b68a4;
}
.site-header {
	position: relative;
	z-index: 100;
	background: transparent;
	color: inherit;
}
.site-header__middle {
	height: 104px;
}
.site-header__logo,
.site-header__middle {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
.site-header__logo {
	width: 255px;
	margin-right: 30px;
	-ms-flex-pack: center;
	justify-content: center;
	color: inherit;
}
.site-header__logo a {
	color: inherit;
}
.site-header__logo svg {
	fill: currentColor;
}
.site-header__search {
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.site-header__phone {
	line-height: 1;
	text-align: right;
	margin-left: 80px;
}
.site-header__phone-title {
	font-size: 14px;
	color: #999;
	margin-bottom: 6px;
}
.site-header__phone-number {
	font-size: 20px;
	font-weight: 700;
}
.site-header__nav-panel {
	height: 54px;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.site-header__logo {
		width: 210px;
		margin-right: 24px;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
}
.topbar {
	height: 32px;
	background: #f7f7f7;
	box-shadow: none;
	border-bottom: none;
	font-size: 14px;
	line-height: 14px;
	color: #737373;
	position: relative;
	z-index: 20;
}
.topbar__container {
	height: 100%;
}
.topbar__item,
.topbar__row {
	height: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin: 0;
}
.topbar__item-value {
	color: #1b68a4;
	transition: all 0.1s;
}
.topbar__spring {
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.topbar__item--link + .topbar__item--link {
	margin-left: 16px;
}
.topbar-dropdown,
.topbar-dropdown__btn {
	position: relative;
	height: 100%;
}
.topbar-dropdown__btn {
	border: none;
	padding: 0 20px 0 10px;
	cursor: pointer;
	transition: all 0.1s;
	background: transparent;
	color: inherit;
	fill: #bfbfbf;
}
.topbar-dropdown__btn:focus {
	outline: none;
}
.topbar-dropdown__btn svg {
	position: absolute;
	right: 7px;
	top: calc(50% - 4px);
}
.topbar-dropdown__body {
	position: absolute;
	right: 0;
	top: 100%;
	visibility: hidden;
	transform-origin: top;
	transform: rotateX(60deg);
	opacity: 0;
	transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
.topbar-dropdown--opened .topbar-dropdown__btn,
.topbar-dropdown__btn:focus,
.topbar-dropdown__btn:hover {
	background: #ebebeb;
	color: #3d464d;
	fill: #999;
}
.topbar-dropdown--opened .topbar-dropdown__btn .topbar__item-value,
.topbar-dropdown__btn:focus .topbar__item-value,
.topbar-dropdown__btn:hover .topbar__item-value {
	color: #1b68a4;
}
.topbar-dropdown--opened .topbar-dropdown__body {
	z-index: 1;
	transition-delay: 0s, 0s, 0s;
	opacity: 1;
	visibility: visible;
	transform: rotateX(0deg);
}
.topbar-link {
	color: inherit;
	transition: color 0.3s;
}
.topbar-link:hover {
	color: #1b68a4;
}
.footer-contacts__title {
	margin-bottom: 25px;
}
.footer-contacts__text {
	font-size: 15px;
	line-height: 22px;
	list-style: none;
	margin: 0;
	padding: 0 20px 0 0;
}
.footer-contacts__text a {
	color: inherit;
}
.footer-contacts__contacts {
	font-size: 15px;
	line-height: 20px;
	list-style: none;
	padding: 0;
	margin: 16px 0 0;
}
.footer-contacts__contacts a {
	color: inherit;
}
.footer-contacts__contacts li {
	padding: 4px 0;
	position: relative;
}
.footer-contacts__icon {
	display: inline-block;
	text-align: center;
	width: 22px;
	margin-right: 2px;
}
@media (max-width: 767px) {
	.footer-contacts {
		text-align: center;
	}
	.footer-contacts__title {
		font-size: 28px;
		margin-bottom: 16px;
	}
	.footer-contacts__text {
		padding: 0;
	}
}
.footer-links__title {
	margin-bottom: 22px;
}
.footer-links__list {
	font-size: 15px;
	line-height: 28px;
	list-style: none;
	padding: 0;
	margin: 0;
}
.footer-links__list a {
	color: inherit;
	transition: 0.15s;
}
.footer-links__list a:hover {
	color: #1b68a4;
}
@media (max-width: 767px) {
	.footer-links__title {
		margin-bottom: 12px;
	}
	.footer-links {
		margin-top: 42px;
		text-align: center;
	}
}
.footer-newsletter__title {
	margin-bottom: 25px;
}
.footer-newsletter__text {
	font-size: 15px;
	line-height: 22px;
	margin-bottom: 14px;
}
.footer-newsletter__text--social {
	margin-top: 20px;
	margin-bottom: 0;
}
.footer-newsletter__social-links {
	display: -ms-flexbox;
	display: flex;
	margin: 8px 0 0;
	padding: 0;
	list-style: none;
}
.footer-newsletter__social-link + .footer-newsletter__social-link {
	margin-left: 7px;
}
.footer-newsletter__social-link a {
	font-size: 16px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: inherit;
	width: 34px;
	height: 34px;
	border-radius: 17px;
	transition: all 0.2s;
}
.footer-newsletter__social-link a:hover {
	opacity: 0.8;
}
.footer-newsletter__social-link a {
	color: #fff;
}
.footer-newsletter__social-link--facebook a {
	background: #3c5a99;
}
.footer-newsletter__social-link--twitter a {
	background: #00a2e8;
}
.footer-newsletter__social-link--youtube a {
	background: #e52e2e;
}
.footer-newsletter__social-link--instagram a {
	background: #815dc7;
}
.footer-newsletter__social-link--rss a {
	background: #ffc338;
}
.footer-newsletter__form {
	display: -ms-flexbox;
	display: flex;
	max-width: 380px;
}
.footer-newsletter__form-button {
	margin-left: 6px;
}
@media (max-width: 991px) {
	.footer-newsletter {
		margin-top: 42px;
		max-width: 420px;
	}
	.footer-newsletter__title {
		margin-bottom: 16px;
	}
}
@media (max-width: 767px) {
	.footer-newsletter {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
	.footer-newsletter__title {
		font-size: 28px;
	}
	.footer-newsletter__form {
		margin: 0 auto;
	}
	.footer-newsletter__social-links {
		margin-top: 16px;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
.site-footer {
	background: transparent;
	border-top: 1px solid #ebebeb;
}
.site-footer__widgets {
	padding: 48px 0 54px;
}
.site-footer__bottom {
	height: 54px;
	border-top: 1px solid #ebebeb;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.site-footer__copyright {
	font-size: 14px;
}
.site-footer__copyright a:hover {
	text-decoration: underline;
}
.site-footer__payments img {
	max-width: 100%;
}
.site-footer__widget + .site-footer__widget {
	margin-top: 24px;
}
@media (max-width: 767px) {
	.site-footer__bottom {
		padding: 20px 0 24px;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		height: auto;
	}
	.site-footer__payments {
		-ms-flex-order: -1;
		order: -1;
	}
	.site-footer__copyright + .site-footer__payments {
		margin-bottom: 30px;
	}
	.site-footer__copyright,
	.site-footer__payments {
		width: 100%;
		text-align: center;
	}
}
.about-us__image {
	/* background-image: url(../images/aboutus.jpg); */
	background-size: cover;
	background-position: bottom;
	height: 500px;
}
.about-us__body {
	background: #fff;
	min-height: 380px;
	margin-top: -380px;
	border-radius: 4px 4px 0 0;
	padding: 75px 92px;
}
.about-us__title {
	margin-bottom: 50px;
}
.about-us__team,
.about-us__text,
.about-us__title {
	text-align: center;
}
.about-us__team {
	margin-top: 75px;
}
.about-us__team-subtitle {
	margin-top: 24px;
}
.about-us__teammates {
	margin-top: 42px;
}
.teammates .owl-carousel .owl-dots {
	margin-top: 20px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
}
.teammates .owl-carousel .owl-dots.disabled {
	display: none;
}
.teammates .owl-carousel button.owl-dot {
	display: block;
	color: #e5e5e5;
	transition: color 0.2s;
}
.teammates .owl-carousel button.owl-dot:before {
	display: block;
	content: '';
	width: 12px;
	height: 12px;
	border-radius: 6px;
	background: currentColor;
	margin: 6px;
}
.teammates .owl-carousel button.owl-dot:focus {
	outline: none;
}
.teammates .owl-carousel button.owl-dot:hover {
	color: #ccc;
}
.teammates .owl-carousel button.owl-dot.active {
	color: #1b68a4;
}
.teammate__avatar img {
	max-width: 100%;
	border-radius: 2px;
}
.teammate__name {
	font-size: 18px;
	font-weight: 500;
	margin-top: 16px;
}
.teammate__position {
	font-size: 14px;
}
@media (max-width: 991px) and (min-width: 768px) {
	.about-us__image {
		height: 460px;
	}
	.about-us__title {
		margin-bottom: 36px;
	}
	.about-us__body {
		padding: 50px;
	}
	.about-us__team {
		margin-top: 75px;
	}
}
@media (max-width: 767px) and (min-width: 576px) {
	.about-us__image {
		height: 360px;
	}
	.about-us__title {
		margin-bottom: 24px;
	}
	.about-us__body {
		min-height: 290px;
		margin-top: -290px;
		padding: 40px;
	}
	.about-us__team {
		margin-top: 50px;
	}
}
@media (max-width: 767px) {
	.about-us__image {
		height: 330px;
	}
	.about-us__body {
		min-height: 290px;
		margin-top: -290px;
		padding: 30px 24px;
	}
	.about-us__title {
		margin-bottom: 20px;
	}
	.about-us__team {
		margin-top: 50px;
	}
}
.checkout__totals {
	font-size: 15px;
	line-height: 19px;
	width: 100%;
	margin-bottom: 40px;
}
.checkout__totals td,
.checkout__totals th {
	padding: 4px 0;
}
.checkout__totals th {
	font-weight: 700;
}
.checkout__totals tr > :first-child {
	padding-right: 20px;
}
.checkout__totals tr > :last-child {
	text-align: right;
}
.checkout__totals-header tr:last-child > * {
	padding-bottom: 10px;
}
.checkout__totals-products tr:first-child > * {
	padding-top: 20px;
	border-top: 1px solid #ebebeb;
}
.checkout__totals-products tr:last-child > * {
	padding-bottom: 20px;
	border-bottom: 1px solid #ebebeb;
}
.checkout__totals-subtotals tr:first-child > * {
	padding-top: 20px;
}
.checkout__totals-subtotals tr:last-child > * {
	padding-bottom: 20px;
	border-bottom: 1px solid #ebebeb;
}
.checkout__totals-footer {
	font-size: 24px;
}
.checkout__totals-footer th {
	font-weight: 500;
}
.checkout__totals-footer tr:first-child > * {
	padding-top: 20px;
}
.payment-methods {
	margin-bottom: 30px;
}
.payment-methods__list {
	list-style: none;
	padding: 0;
	margin: 0;
}
.payment-methods__item {
	border-radius: 2px;
	background: transparent;
	border: 1px solid #e5e5e5;
}
.payment-methods__item:hover {
	background: #f7f7f7;
	border: 1px solid #e5e5e5;
}
.payment-methods__item + .payment-methods__item {
	margin-top: 6px;
}
.payment-methods__item-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 8px 14px;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: 0.01em;
	cursor: pointer;
	margin-bottom: 0;
}
.payment-methods__item-radio {
	margin-right: 10px;
}
.payment-methods__item-container {
	overflow: hidden;
	height: 0;
	transition: height 0.2s;
}
.payment-methods__item-description {
	font-size: 15px;
	line-height: 20px;
	padding: 2px 16px 13px;
}
.payment-methods__item--active,
.payment-methods__item--active:hover {
	background: #f7f7f7;
	border: 1px solid #e5e5e5;
}
.payment-methods__item--active .payment-methods__item-container {
	height: auto;
}
.checkout__agree {
	margin-bottom: 36px;
}
.checkout__agree a:hover {
	text-decoration: underline;
}
.contact-us__map {
	position: relative;
	height: 440px;
	margin: -2px;
	margin-bottom: 6px;
	overflow: hidden;
	border-radius: 2px 2px 0 0;
}
.contact-us__map iframe {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}
@media (max-width: 1199px) {
	.contact-us__map {
		height: 400px;
	}
}
@media (max-width: 991px) {
	.contact-us__map {
		height: 380px;
	}
}
@media (max-width: 767px) {
	.contact-us__map {
		height: 360px;
	}
}
@media (max-width: 575px) {
	.contact-us__map {
		height: 320px;
	}
}
.faq__section + .faq__section {
	margin-top: 70px;
}
.faq__section-title {
	margin-bottom: 20px;
}
.faq__section-title h3 {
	font-size: 22px;
	font-weight: 500;
}
.faq__section-body {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
	padding: 48px 44px;
}
@media (max-width: 991px) {
	.faq__section + .faq__section {
		margin-top: 40px;
	}
	.faq__section-body {
		padding: 28px 24px;
	}
	.faq__section-column + .faq__section-column {
		margin-top: 1.5em;
	}
}
.not-found {
	text-align: center;
}
.not-found__404 {
	font-size: 80px;
	font-weight: 700;
	color: #ebebeb;
	padding: 40px 0 20px;
}
.not-found__content {
	width: 480px;
	max-width: 100%;
	margin: 0 auto;
}
.not-found__title {
	margin-bottom: 30px;
}
.not-found__text {
	margin-bottom: 20px;
}
.not-found__search {
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 30px;
}
.not-found__search-input {
	width: 1px;
	-ms-flex-positive: 1;
	flex-grow: 1;
	margin-right: 10px;
}
@media (max-width: 991px) {
	.not-found__404 {
		font-size: 60px;
	}
}
@media (max-width: 767px) {
	.not-found__404 {
		font-size: 50px;
		line-height: 54px;
		padding: 40px 0 32px;
	}
}
.cart__actions {
	margin-top: 2rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.cart__coupon-form {
	width: calc(50% - 15px);
	display: -ms-flexbox;
	display: flex;
}
.cart__coupon-form .btn {
	margin-left: 0.5rem;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.cart__buttons {
	width: calc(50% - 15px);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: end;
	justify-content: flex-end;
}
.cart__update-button {
	margin-left: 0.5rem;
}
.cart__totals {
	font-size: 16px;
	line-height: 20px;
	width: 100%;
	margin-bottom: 40px;
}
.cart__totals a:hover {
	text-decoration: underline;
}
.cart__totals td,
.cart__totals th {
	vertical-align: top;
	padding: 6px 0;
}
.cart__totals th {
	font-weight: 700;
}
.cart__totals tr > :first-child {
	padding-right: 20px;
}
.cart__totals tr > :last-child {
	text-align: right;
}
.cart__totals-header tr:last-child > * {
	padding-bottom: 10px;
}
.cart__totals-body tr:first-child > * {
	padding-top: 20px;
	border-top: 1px solid #ebebeb;
}
.cart__totals-body tr:last-child > * {
	padding-bottom: 20px;
	border-bottom: 1px solid #ebebeb;
}
.cart__totals-footer {
	font-size: 24px;
}
.cart__totals-footer th {
	font-weight: 500;
}
.cart__totals-footer tr:first-child > * {
	padding-top: 20px;
}
.cart__calc-shipping {
	margin-top: 2px;
	font-size: 15px;
}
@media (max-width: 767px) {
	.cart__actions {
		margin-top: 1.5rem;
		display: block;
	}
	.cart__coupon-form {
		width: 100%;
	}
	.cart__buttons {
		-ms-flex-direction: column;
		flex-direction: column;
		margin-top: 2.5rem;
		width: 100%;
	}
	.cart__update-button {
		margin-left: 0;
		-ms-flex-order: -1;
		order: -1;
		margin-bottom: 0.75rem;
	}
}
@media (max-width: 479px) {
	.cart__checkout-button {
		font-size: 1.125rem;
		height: calc(2.75rem + 2px);
		padding: 0.8125rem 2rem;
		line-height: 1;
		font-weight: 500;
	}
	.cart__checkout-button.btn-svg-icon {
		width: calc(2.75rem + 2px);
	}
}
@media (max-width: 379px) {
	.cart__checkout-button {
		font-size: 1rem;
		height: calc(2.25rem + 2px);
		line-height: 1.5;
		padding: 0.375rem 1.25rem;
		font-weight: 500;
	}
	.cart__checkout-button.btn-svg-icon {
		width: calc(2.25rem + 2px);
	}
}
.cart-table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
}
.cart-table__product-name {
	color: inherit;
}
.cart-table__options {
	list-style: none;
	padding: 0;
	margin: 4px 0 0;
	font-size: 14px;
	color: #999;
	line-height: 19px;
}
.cart-table__options li {
	padding-left: 12px;
	position: relative;
}
.cart-table__options li:before {
	content: '';
	display: block;
	position: absolute;
	left: 1px;
	top: 7px;
	width: 4px;
	height: 4px;
	border-radius: 2px;
	background: currentColor;
}
@media (min-width: 768px) {
	.cart-table__column {
		padding: 12px 18px;
		border-top: 1px solid #ebebeb;
	}
	.cart-table__column:first-child {
		border-left: 1px solid #ebebeb;
	}
	.cart-table__column:last-child {
		border-right: 1px solid #ebebeb;
	}
	.cart-table__head {
		background: #f7f7f7;
		font-size: 15px;
	}
	.cart-table__head .cart-table__column {
		font-weight: 500;
	}
	.cart-table__head .cart-table__column:first-child {
		border-top-left-radius: 2.5px;
	}
	.cart-table__head .cart-table__column:last-child {
		border-top-right-radius: 2.5px;
	}
	.cart-table__body .cart-table__column {
		padding-top: 18px;
		padding-bottom: 18px;
	}
	.cart-table__body .cart-table__row:last-child .cart-table__column {
		border-bottom: 1px solid #ebebeb;
	}
	.cart-table__body
		.cart-table__row:last-child
		.cart-table__column:first-child {
		border-bottom-left-radius: 2.5px;
	}
	.cart-table__body .cart-table__row:last-child .cart-table__column:last-child {
		border-bottom-right-radius: 2.5px;
	}
	.cart-table__column--image {
		width: 1px;
		text-align: center;
	}
	.cart-table__column--image img {
		width: 80px;
	}
	.cart-table__column--price {
		text-align: right;
		width: 170px;
	}
	.cart-table__column--quantity {
		padding-left: 90px;
		text-align: center;
		width: 1px;
	}
	.cart-table__column--total {
		text-align: right;
		width: 170px;
	}
	.cart-table__column--remove {
		width: 1px;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.cart-table__column--quantity {
		padding-left: 40px;
	}
	.cart-table__column--price,
	.cart-table__column--total {
		width: 130px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.cart-table__column--quantity {
		padding-left: 0;
		padding-right: 0;
	}
	.cart-table__column--price,
	.cart-table__column--total {
		width: 1px;
	}
	.cart-table__column--remove {
		padding-left: 0;
	}
}
@media (max-width: 767px) {
	.cart-table {
		display: block;
	}
	.cart-table__head {
		display: none;
	}
	.cart-table__body {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.cart-table__row {
		position: relative;
		width: 100%;
		display: block;
		border: 1px solid #ebebeb;
		border-radius: 2px;
	}
	.cart-table__row + .cart-table__row {
		margin-top: 15px;
	}
	.cart-table__column {
		display: block;
		padding: 0;
	}
	.cart-table__column--image {
		padding: 20px 20px 10px;
		text-align: center;
	}
	.cart-table__column--image img {
		width: 100%;
		max-width: 160px;
	}
	.cart-table__column--product {
		text-align: center;
		border-bottom: 1px solid #ebebeb;
		padding: 0 20px 18px;
	}
	.cart-table__column--price,
	.cart-table__column--quantity,
	.cart-table__column--total {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: 8px 20px;
	}
	.cart-table__column--price:before,
	.cart-table__column--quantity:before,
	.cart-table__column--total:before {
		text-align: left;
		font-weight: 500;
		width: 55%;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		content: attr(data-title) ': ';
	}
	.cart-table__column--quantity .input-number {
		width: 110px;
	}
	.cart-table__column--price {
		padding-top: 18px;
	}
	.cart-table__column--total {
		padding-bottom: 18px;
	}
	.cart-table__column--remove {
		position: absolute;
		top: 5px;
		right: 5px;
	}
	.cart-table__options li:before {
		display: none;
	}
}
.category-card,
.category-card__body {
	display: -ms-flexbox;
	display: flex;
}
.category-card__body {
	border-radius: 2px;
	background: #fff;
	box-shadow: 0 1px 16px rgba(0, 0, 0, 0.04);
	padding: 24px 20px;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.category-card__image {
	width: 130px;
	margin-right: 24px;
}
.category-card__image img {
	max-width: 100%;
}
.category-card__content {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.category-card__name {
	margin-top: -2px;
	font-weight: 500;
	font-size: 17px;
	line-height: 19px;
}
.category-card__name a {
	color: inherit;
	transition: 0.15s;
}
.category-card__name a:hover {
	color: #1b68a4;
}
.category-card__links {
	list-style: none;
	margin: 14px 0 0;
	padding: 0;
	font-size: 14px;
	line-height: 22px;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.category-card__links a {
	color: #6c757d;
	transition: 0.15s;
}
.category-card__links a:hover {
	color: #1b68a4;
}
.category-card__all {
	margin-top: 14px;
	font-size: 14px;
}
.category-card__all a:hover {
	text-decoration: underline;
}
.category-card__products {
	display: none;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.category-card--layout--classic .category-card__image {
		width: 90px;
	}
}
@media (min-width: 480px) and (max-width: 991px) {
	.category-card--layout--classic .category-card__body {
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 24px 28px;
	}
	.category-card--layout--classic .category-card__image {
		width: auto;
		margin: 0 0 18px;
	}
}
@media (min-width: 400px) and (max-width: 479px) {
	.category-card--layout--classic .category-card__image {
		width: 40%;
	}
}
@media (max-width: 399px) {
	.category-card--layout--classic .category-card__body {
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 24px 28px;
		text-align: center;
	}
	.category-card--layout--classic .category-card__image {
		width: 120px;
		margin: 0 auto 18px;
	}
}
.category-card--layout--compact .category-card__body {
	padding: 16px 30px;
	-ms-flex-align: center;
	align-items: center;
}
.category-card--layout--compact .category-card__image {
	width: 74px;
	margin-right: 30px;
}
.category-card--layout--compact .category-card__all,
.category-card--layout--compact .category-card__links {
	display: none;
}
.category-card--layout--compact .category-card__name {
	margin-top: 0;
	font-size: 17px;
	line-height: 19px;
}
.category-card--layout--compact .category-card__products {
	display: block;
	font-size: 15px;
	color: #999;
	margin-top: 5px;
}
.compare-table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
	font-size: 15px;
	line-height: 18px;
}
.compare-table tr > * {
	border-top: 1px solid #ebebeb;
	border-left: 1px solid #ebebeb;
}
.compare-table tr > :last-child {
	border-right: 1px solid #ebebeb;
}
.compare-table > :first-child > tr:first-child > :first-child {
	border-top-left-radius: 2.5px;
}
.compare-table > :first-child > tr:first-child > :last-child {
	border-top-right-radius: 2.5px;
}
.compare-table > :last-child > tr:last-child > :first-child {
	border-bottom-left-radius: 2.5px;
}
.compare-table > :last-child > tr:last-child > :last-child {
	border-bottom-right-radius: 2.5px;
}
.compare-table > :last-child > tr:last-child > * {
	border-bottom: 1px solid #ebebeb;
}
.compare-table th {
	min-width: 200px;
	text-align: right;
	background: #f7f7f7;
	font-weight: 500;
}
.compare-table td {
	min-width: 180px;
	max-width: 180px;
	text-align: center;
	vertical-align: top;
}
.compare-table td,
.compare-table th {
	padding: 14px 16px;
}
.compare-table__product-link {
	display: block;
	padding: 8px 8px 0;
	color: inherit;
}
.compare-table__product-image {
	margin-bottom: 14px;
	width: 100%;
}
.compare-table__product-image img {
	width: 140px;
	max-width: 100%;
}
.compare-table__product-name {
	line-height: 20px;
}
.compare-table__product-rating {
	padding-top: 3px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
}
.compare-table__product-rating-legend {
	margin-top: 8px;
	font-size: 13px;
	line-height: 1;
	color: #b3b3b3;
}
.compare-table__product-badge {
	font-size: 12px;
}
.product__content {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 50% 50%;
	grid-template-columns: [gallery] calc(50% - 20px) [info] calc(50% - 20px);
	grid-template-rows: auto auto auto auto auto;
	grid-column-gap: 40px;
}
.product__gallery {
	-ms-grid-row: 1;
	-ms-grid-row-span: 6;
	-ms-grid-column: 1;
	grid-row-start: 1;
	grid-row-end: 6;
	min-height: 0;
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
	.product__gallery {
		margin-right: 40px;
	}
}
.product__info {
	-ms-grid-row: 1;
	-ms-grid-column: 2;
	position: relative;
	min-height: 0;
}
.product__sidebar {
	-ms-grid-row: 2;
	-ms-grid-column: 2;
}
.product__footer {
	-ms-grid-row: 3;
	-ms-grid-column: 2;
}
.product__wishlist-compare {
	display: none;
}
.product__name {
	margin-bottom: 22px;
}
.product__rating {
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 5px;
}
.product__rating-stars {
	padding-top: 2px;
	margin-right: 12px;
}
.product__rating-legend {
	font-size: 14px;
	line-height: 20px;
	color: #b3b3b3;
}
.product__rating-legend a {
	color: inherit;
	transition: color 0.2s;
}
.product__rating-legend a:hover {
	color: #1b68a4;
}
.product__rating-legend span {
	content: '/';
	padding: 0 7px;
}
.product__description {
	font-size: 16px;
}
.product__features {
	display: none;
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 14px;
}
.product__features li {
	padding: 1px 0 1px 13px;
	position: relative;
}
.product__features li:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 9px;
	width: 5px;
	height: 5px;
	border-radius: 2.5px;
	border: 1px solid currentColor;
}
.product__meta {
	list-style: none;
	margin: 12px 0 0;
	padding: 12px 0 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	color: #999;
	font-size: 14px;
	border-top: 1px solid #ebebeb;
}
.product__meta li {
	margin-right: 18px;
}
.product__meta a {
	color: inherit;
	transition: color 0.2s;
}
.product__meta a:hover {
	color: #1b68a4;
}
.product__availability {
	display: none;
}
.product__prices {
	margin-top: 18px;
	margin-bottom: 14px;
	font-size: 28px;
	font-weight: 700;
	letter-spacing: -0.03em;
	color: #3d464d;
}
.product__new-price {
	color: #ff2626;
}
.product__old-price {
	color: #b3b3b3;
	font-weight: 400;
	font-size: 20px;
	text-decoration: line-through;
}
.product__option {
	margin-bottom: 18px;
}
.product__option:last-child {
	margin-bottom: 0;
}
.product__option-label {
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 500;
	color: #6c757d;
	padding-bottom: 2px;
}
.product__actions {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: -4px;
}
.product__actions-item {
	margin: 4px;
}
.product__quantity {
	width: 120px;
}
.product__footer {
	margin-top: 32px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.product__share-links {
	margin: 2px 0 2px 24px;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.product__name {
		font-size: 24px;
		margin-bottom: 18px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.product__content {
		-ms-grid-columns: 44% 56%;
		grid-template-columns: [gallery] calc(44% - 15px) [info] calc(56% - 15px);
		grid-column-gap: 30px;
	}
}
@media (min-width: 768px) and (max-width: 991px) and (-ms-high-contrast: none),
	screen and (min-width: 768px) and (max-width: 991px) and (-ms-high-contrast: active) {
	.product__gallery {
		margin-right: 30px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.product__name {
		font-size: 24px;
		margin-bottom: 18px;
	}
	.product__footer {
		display: block;
		margin-top: 18px;
	}
	.product__share-links {
		margin: 12px 0 0;
	}
}
@media (max-width: 767px) {
	.product__content {
		display: block;
	}
	.product__gallery {
		margin-bottom: 24px;
	}
	.product__name {
		font-size: 24px;
		margin-bottom: 18px;
	}
}
@media (max-width: 559px) {
	.product__footer {
		display: block;
		margin-top: 24px;
	}
	.product__share-links {
		margin: 12px 0 0;
	}
}
@media (min-width: 992px) {
	.product--layout--columnar .product__content {
		-ms-grid-columns: 380px auto 260px;
		grid-template-columns: [gallery] 380px [info] auto [sidebar] 260px;
		grid-template-rows: auto auto auto auto;
		grid-column-gap: 0;
	}
	.product--layout--columnar .product__gallery {
		grid-row-start: 1;
		grid-row-end: 4;
		min-height: 0;
	}
}
@media (min-width: 992px) and (-ms-high-contrast: none),
	screen and (min-width: 992px) and (-ms-high-contrast: active) {
	.product--layout--columnar .product__gallery {
		margin-right: 0;
	}
}
@media (min-width: 992px) {
	.product--layout--columnar .product__info {
		-ms-grid-row: 1;
		-ms-grid-column: 2;
		grid-row: 1;
		grid-column: info;
		padding: 0 30px;
		min-height: 0;
	}
	.product--layout--columnar .product__sidebar {
		-ms-grid-row: 1;
		-ms-grid-row-span: 4;
		-ms-grid-column: 3;
		grid-column: sidebar;
		grid-row-start: 1;
		grid-row-end: 4;
		min-height: 0;
		border-left: 2px solid #f0f0f0;
		padding-top: 10px;
		padding-left: 30px;
		padding-bottom: 20px;
	}
	.product--layout--columnar .product__footer {
		-ms-grid-row: 2;
		-ms-grid-column: 2;
		grid-row: 2;
		grid-column: info;
		padding: 0 30px;
		min-height: 0;
	}
	.product--layout--columnar .product__wishlist-compare {
		position: absolute;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		right: 8px;
	}
	.product--layout--columnar .product__wishlist-compare > * + * {
		margin-top: 2px;
	}
	.product--layout--columnar .product__name {
		font-size: 24px;
		margin-bottom: 12px;
		padding-right: 16px;
	}
	.product--layout--columnar .product__description {
		margin: 10px 0 12px;
		font-size: 15px;
	}
	.product--layout--columnar .product__features {
		display: block;
	}
	.product--layout--columnar .product__meta {
		margin: 12px 0 0;
		padding: 0;
		border-top: none;
	}
	.product--layout--columnar .product__meta-availability {
		display: none;
	}
	.product--layout--columnar .product__footer {
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.product--layout--columnar .product__share-links {
		margin: 12px 0 0 1px;
	}
	.product--layout--columnar .product__prices {
		margin-top: 20px;
		margin-bottom: 24px;
		line-height: 28px;
	}
	.product--layout--columnar .product__new-price {
		display: block;
	}
	.product--layout--columnar .product__quantity {
		width: 88px;
	}
	.product--layout--columnar .product__actions {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
	.product--layout--columnar .product__actions-item--addtocart {
		-ms-flex-positive: 1;
		flex-grow: 1;
	}
	.product--layout--columnar .product__actions-item--addtocart .btn {
		width: 100%;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
	.product--layout--columnar .product__actions-item--compare,
	.product--layout--columnar .product__actions-item--wishlist {
		display: none;
	}
	.product--layout--columnar .product__availability {
		display: block;
		font-size: 14px;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.product--layout--columnar .product__content {
		-ms-grid-columns: 320px auto 200px;
		grid-template-columns: [gallery] 320px [info] auto [sidebar] 200px;
	}
	.product--layout--columnar .product__sidebar {
		padding-left: 24px;
	}
	.product--layout--columnar .product__option {
		margin-bottom: 12px;
	}
	.product--layout--columnar .product__actions {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0;
	}
	.product--layout--columnar .product__quantity {
		width: 100px;
	}
	.product--layout--columnar .product__actions-item {
		margin: 0;
	}
	.product--layout--columnar .product__actions-item--addtocart {
		margin-top: 16px;
	}
}
@media (min-width: 992px) {
	.product--layout--sidebar .product__content {
		-ms-grid-columns: 50% 50%;
		grid-template-columns: [gallery] calc(50% - 16px) [info] calc(50% - 16px);
		grid-column-gap: 32px;
	}
}
@media (min-width: 992px) and (-ms-high-contrast: none),
	screen and (min-width: 992px) and (-ms-high-contrast: active) {
	.product--layout--sidebar .product__gallery {
		margin-right: 32px;
	}
}
@media (min-width: 992px) {
	.product--layout--sidebar .product__name {
		font-size: 24px;
		margin-bottom: 12px;
	}
	.product--layout--sidebar .product__footer {
		display: block;
		margin-top: 18px;
	}
	.product--layout--sidebar .product__share-links {
		margin: 12px 0 0;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.product--layout--quickview .product__content {
		grid-template-columns: [gallery] calc(50% - 16px) [info] calc(50% - 16px);
		grid-column-gap: 32px;
	}
	.product--layout--quickview .product__name {
		margin-bottom: 12px;
	}
	.product--layout--quickview .product__footer {
		display: block;
		margin-top: 18px;
	}
	.product--layout--quickview .product__share-links {
		margin: 12px 0 0;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.product--layout--quickview .product__content {
		display: block;
	}
	.product--layout--quickview .product__gallery {
		margin-bottom: 24px;
	}
	.product--layout--quickview .product__name {
		font-size: 24px;
		margin-bottom: 18px;
	}
}
.product-gallery__featured {
	box-shadow: inset 0 0 0 2px #f2f2f2;
	padding: 2px;
	border-radius: 2px;
}
.product-gallery__featured a {
	display: block;
	padding: 20px;
}
.product-gallery__carousel {
	margin-top: 16px;
}
.product-gallery__carousel-item {
	display: block;
	box-shadow: inset 0 0 0 2px #f2f2f2;
	padding: 12px;
	border-radius: 2px;
	cursor: pointer;
}
.product-gallery__carousel-item--active {
	box-shadow: inset 0 0 0 2px #1b68a4;
}
.product-tabs {
	margin-top: 50px;
}
.product-tabs__list {
	display: -ms-flexbox;
	display: flex;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	margin-bottom: -2px;
}
.product-tabs__list:after,
.product-tabs__list:before {
	content: '';
	display: block;
	width: 8px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.product-tabs__item {
	font-size: 20px;
	padding: 18px 48px;
	border-bottom: 2px solid transparent;
	color: inherit;
	font-weight: 500;
	border-radius: 3px 3px 0 0;
	transition: all 0.15s;
	cursor: pointer;
}
.product-tabs__item:hover {
	color: inherit;
	background: #f7f7f7;
	border-bottom-color: #d9d9d9;
}
.product-tabs__item:first-child {
	margin-left: auto;
}
.product-tabs__item:last-child {
	margin-right: auto;
}
.product-tabs__item--active {
	transition-duration: 0.3s;
}
.product-tabs__item--active,
.product-tabs__item--active:hover {
	cursor: default;
	border-bottom-color: #1b68a4;
	background: transparent;
}
.product-tabs__content {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
	padding: 80px 90px;
}
.product-tabs__pane {
	overflow: hidden;
	height: 0;
	opacity: 0;
	transition: opacity 0.5s;
}
.product-tabs__pane--active {
	overflow: visible;
	height: auto;
	opacity: 1;
}
.product-tabs--layout--sidebar .product-tabs__item {
	padding: 14px 30px;
}
.product-tabs--layout--sidebar .product-tabs__content {
	padding: 48px 50px;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.product-tabs__content {
		padding: 60px 70px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.product-tabs .product-tabs__item {
		padding: 14px 30px;
	}
	.product-tabs .product-tabs__content {
		padding: 40px 50px;
	}
}
@media (max-width: 767px) {
	.product-tabs .product-tabs__item {
		padding: 14px 30px;
	}
	.product-tabs .product-tabs__content {
		padding: 24px;
	}
}
.products-list__body {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.products-list__body,
.products-list__item {
	display: -ms-flexbox;
	display: flex;
}
.products-list__item .product-card {
	width: 100%;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.products-list[data-layout^='grid-'] .product-card .product-card__image {
	padding: 18px 18px 20px;
}
.products-list[data-layout^='grid-'] .product-card .product-card__info {
	padding: 0 24px;
}
.products-list[data-layout^='grid-'] .product-card .product-card__actions {
	padding: 0 24px 24px;
}
.products-list[data-layout^='grid-'] .product-card .product-card__availability,
.products-list[data-layout^='grid-'] .product-card .product-card__description,
.products-list[data-layout^='grid-']
	.product-card
	.product-card__features-list {
	display: none;
}
.products-list[data-layout^='grid-'][data-with-features='true']
	.product-card
	.product-card__features-list {
	display: block;
}
.products-list[data-layout='grid-3-sidebar'] .products-list__body {
	margin: -8px -6px;
}
.products-list[data-layout='grid-3-sidebar'] .products-list__item {
	width: calc(33.33333% - 12px);
	margin: 8px 6px;
}
.products-list[data-layout='grid-3-sidebar']
	.product-card
	.product-card__buttons
	.btn {
	font-size: 0.875rem;
	height: calc(1.875rem + 2px);
	line-height: 1.25;
	padding: 0.375rem 1rem;
	font-weight: 500;
}
.products-list[data-layout='grid-3-sidebar']
	.product-card
	.product-card__buttons
	.btn.btn-svg-icon {
	width: calc(1.875rem + 2px);
}
@media (hover: hover) {
	.products-list[data-layout='grid-3-sidebar']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.products-list[data-layout='grid-3-sidebar'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -56px;
	}
	.products-list[data-layout='grid-3-sidebar']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
	.products-list[data-layout='grid-3-sidebar']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.products-list[data-layout='grid-3-sidebar'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -56px;
	}
	.products-list[data-layout='grid-3-sidebar']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (max-width: 1199px) and (min-width: 480px) {
	.products-list[data-layout='grid-3-sidebar']
		.product-card
		.product-card__image {
		padding: 15px;
	}
	.products-list[data-layout='grid-3-sidebar']
		.product-card
		.product-card__badges-list {
		left: 16px;
		top: 16px;
	}
	.products-list[data-layout='grid-3-sidebar']
		.product-card
		.product-card__info {
		padding: 0 15px;
	}
	.products-list[data-layout='grid-3-sidebar']
		.product-card
		.product-card__actions {
		padding: 0 15px 15px;
	}
	.products-list[data-layout='grid-3-sidebar']
		.product-card
		.product-card__buttons
		.btn {
		font-size: 0.8125rem;
		height: calc(1.5rem + 2px);
		line-height: 1.25;
		padding: 0.25rem 0.5625rem;
		font-weight: 500;
	}
	.products-list[data-layout='grid-3-sidebar']
		.product-card
		.product-card__buttons
		.btn.btn-svg-icon {
		width: calc(1.5rem + 2px);
	}
}
@media (max-width: 1199px) and (min-width: 480px) and (hover: hover) {
	.products-list[data-layout='grid-3-sidebar']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.products-list[data-layout='grid-3-sidebar'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.products-list[data-layout='grid-3-sidebar']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (max-width: 1199px) and (min-width: 480px) and (-ms-high-contrast: none),
	screen and (max-width: 1199px) and (min-width: 480px) and (-ms-high-contrast: active) {
	.products-list[data-layout='grid-3-sidebar']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.products-list[data-layout='grid-3-sidebar'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.products-list[data-layout='grid-3-sidebar']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (max-width: 991px) and (min-width: 768px) {
	.products-list[data-layout='grid-3-sidebar'] .products-list__body {
		margin: -8px -6px;
	}
	.products-list[data-layout='grid-3-sidebar'] .products-list__item {
		width: calc(33.33333% - 12px);
		margin: 8px 6px;
	}
}
@media (max-width: 767px) and (min-width: 480px) {
	.products-list[data-layout='grid-3-sidebar'] .products-list__body {
		margin: -8px -6px;
	}
	.products-list[data-layout='grid-3-sidebar'] .products-list__item {
		width: calc(50% - 12px);
		margin: 8px 6px;
	}
}
@media (max-width: 479px) {
	.products-list[data-layout='grid-3-sidebar'] .products-list__body {
		margin: -8px -6px;
	}
	.products-list[data-layout='grid-3-sidebar'] .products-list__item {
		width: 100%;
		margin: 8px 6px;
	}
}
.products-list[data-layout='grid-4-full'] .products-list__body {
	margin: -8px -6px;
}
.products-list[data-layout='grid-4-full'] .products-list__item {
	width: calc(25% - 12px);
	margin: 8px 6px;
}
.products-list[data-layout='grid-4-full']
	.product-card
	.product-card__buttons
	.btn {
	font-size: 0.875rem;
	height: calc(1.875rem + 2px);
	line-height: 1.25;
	padding: 0.375rem 1rem;
	font-weight: 500;
}
.products-list[data-layout='grid-4-full']
	.product-card
	.product-card__buttons
	.btn.btn-svg-icon {
	width: calc(1.875rem + 2px);
}
@media (hover: hover) {
	.products-list[data-layout='grid-4-full']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.products-list[data-layout='grid-4-full'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -56px;
	}
	.products-list[data-layout='grid-4-full']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
	.products-list[data-layout='grid-4-full']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.products-list[data-layout='grid-4-full'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -56px;
	}
	.products-list[data-layout='grid-4-full']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (max-width: 1199px) and (min-width: 480px) {
	.products-list[data-layout='grid-4-full'] .product-card .product-card__image {
		padding: 15px;
	}
	.products-list[data-layout='grid-4-full']
		.product-card
		.product-card__badges-list {
		left: 16px;
		top: 16px;
	}
	.products-list[data-layout='grid-4-full'] .product-card .product-card__info {
		padding: 0 15px;
	}
	.products-list[data-layout='grid-4-full']
		.product-card
		.product-card__actions {
		padding: 0 15px 15px;
	}
	.products-list[data-layout='grid-4-full']
		.product-card
		.product-card__buttons
		.btn {
		font-size: 0.8125rem;
		height: calc(1.5rem + 2px);
		line-height: 1.25;
		padding: 0.25rem 0.5625rem;
		font-weight: 500;
	}
	.products-list[data-layout='grid-4-full']
		.product-card
		.product-card__buttons
		.btn.btn-svg-icon {
		width: calc(1.5rem + 2px);
	}
}
@media (max-width: 1199px) and (min-width: 480px) and (hover: hover) {
	.products-list[data-layout='grid-4-full']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.products-list[data-layout='grid-4-full'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.products-list[data-layout='grid-4-full']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (max-width: 1199px) and (min-width: 480px) and (-ms-high-contrast: none),
	screen and (max-width: 1199px) and (min-width: 480px) and (-ms-high-contrast: active) {
	.products-list[data-layout='grid-4-full']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.products-list[data-layout='grid-4-full'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.products-list[data-layout='grid-4-full']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (max-width: 991px) and (min-width: 768px) {
	.products-list[data-layout='grid-4-full'] .products-list__body {
		margin: -8px -6px;
	}
	.products-list[data-layout='grid-4-full'] .products-list__item {
		width: calc(33.33333% - 12px);
		margin: 8px 6px;
	}
}
@media (max-width: 767px) and (min-width: 480px) {
	.products-list[data-layout='grid-4-full'] .products-list__body {
		margin: -8px -6px;
	}
	.products-list[data-layout='grid-4-full'] .products-list__item {
		width: calc(50% - 12px);
		margin: 8px 6px;
	}
}
@media (max-width: 479px) {
	.products-list[data-layout='grid-4-full'] .products-list__body {
		margin: -8px -6px;
	}
	.products-list[data-layout='grid-4-full'] .products-list__item {
		width: 100%;
		margin: 8px 6px;
	}
}
.products-list[data-layout='grid-5-full'] .products-list__body {
	margin: -8px -6px;
}
.products-list[data-layout='grid-5-full'] .products-list__item {
	width: calc(20% - 12px);
	margin: 8px 6px;
}
@media (min-width: 480px) {
	.products-list[data-layout='grid-5-full'] .product-card .product-card__image {
		padding: 15px;
	}
	.products-list[data-layout='grid-5-full']
		.product-card
		.product-card__badges-list {
		left: 16px;
		top: 16px;
	}
	.products-list[data-layout='grid-5-full'] .product-card .product-card__info {
		padding: 0 15px;
	}
	.products-list[data-layout='grid-5-full']
		.product-card
		.product-card__actions {
		padding: 0 15px 15px;
	}
	.products-list[data-layout='grid-5-full']
		.product-card
		.product-card__buttons
		.btn {
		font-size: 0.8125rem;
		height: calc(1.5rem + 2px);
		line-height: 1.25;
		padding: 0.25rem 0.5625rem;
		font-weight: 500;
	}
	.products-list[data-layout='grid-5-full']
		.product-card
		.product-card__buttons
		.btn.btn-svg-icon {
		width: calc(1.5rem + 2px);
	}
}
@media (min-width: 480px) and (hover: hover) {
	.products-list[data-layout='grid-5-full']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.products-list[data-layout='grid-5-full'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.products-list[data-layout='grid-5-full']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (min-width: 480px) and (-ms-high-contrast: none),
	screen and (min-width: 480px) and (-ms-high-contrast: active) {
	.products-list[data-layout='grid-5-full']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.products-list[data-layout='grid-5-full'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.products-list[data-layout='grid-5-full']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (max-width: 1199px) and (min-width: 992px) {
	.products-list[data-layout='grid-5-full'] .products-list__body {
		margin: -8px -6px;
	}
	.products-list[data-layout='grid-5-full'] .products-list__item {
		width: calc(25% - 12px);
		margin: 8px 6px;
	}
}
@media (max-width: 991px) and (min-width: 768px) {
	.products-list[data-layout='grid-5-full'] .products-list__body {
		margin: -8px -6px;
	}
	.products-list[data-layout='grid-5-full'] .products-list__item {
		width: calc(33.33333% - 12px);
		margin: 8px 6px;
	}
}
@media (max-width: 767px) and (min-width: 480px) {
	.products-list[data-layout='grid-5-full'] .products-list__body {
		margin: -8px -6px;
	}
	.products-list[data-layout='grid-5-full'] .products-list__item {
		width: calc(50% - 12px);
		margin: 8px 6px;
	}
}
@media (max-width: 479px) {
	.products-list[data-layout='grid-5-full'] .products-list__body {
		margin: -8px -6px;
	}
	.products-list[data-layout='grid-5-full'] .products-list__item {
		width: 100%;
		margin: 8px 6px;
	}
}
.products-list[data-layout='list'] .products-list__body {
	margin: -8px 0;
}
.products-list[data-layout='list'] .products-list__item {
	width: 100%;
	margin: 8px 0;
	display: block;
}
.products-list[data-layout='list'] .product-card {
	-ms-flex-direction: row;
	flex-direction: row;
}
.products-list[data-layout='list'] .product-card .product-card__image {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	padding: 24px;
	width: 210px;
}
.products-list[data-layout='list'] .product-card .product-card__info {
	padding: 20px 24px 20px 4px;
}
.products-list[data-layout='list'] .product-card .product-card__name {
	font-size: 16px;
	line-height: 20px;
	-ms-flex-positive: 0;
	flex-grow: 0;
}
.products-list[data-layout='list'] .product-card .product-card__rating {
	margin-top: 7px;
}
.products-list[data-layout='list'] .product-card .product-card__description {
	color: #6c757d;
	font-size: 15px;
	line-height: 22px;
	margin-top: 12px;
}
.products-list[data-layout='list'] .product-card .product-card__actions {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 190px;
	padding: 16px 20px;
	border-left: 1px solid #ebebeb;
}
.products-list[data-layout='list'] .product-card .product-card__prices {
	margin-top: 16px;
	font-size: 18px;
}
.products-list[data-layout='list'] .product-card .product-card__old-price {
	font-size: 14px;
}
.products-list[data-layout='list'] .product-card .product-card__buttons {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.products-list[data-layout='list'] .product-card .product-card__addtocart {
	display: none;
}
.products-list[data-layout='list']
	.product-card
	.product-card__addtocart--list {
	display: block;
}
.products-list[data-layout='list'] .product-card .product-card__addtocart {
	width: 100%;
}
.products-list[data-layout='list'] .product-card .product-card__addtocart + * {
	margin-left: 0;
}
.products-list[data-layout='list'] .product-card .product-card__addtocart ~ * {
	margin-top: 8px;
}
.products-list[data-layout='list'] .product-card .product-card__addtocart,
.products-list[data-layout='list'] .product-card .product-card__compare,
.products-list[data-layout='list'] .product-card .product-card__wishlist {
	font-size: 0.875rem;
	height: calc(1.875rem + 2px);
	line-height: 1.25;
	padding: 0.375rem 1rem;
	font-weight: 500;
}
.products-list[data-layout='list']
	.product-card
	.product-card__addtocart.btn-svg-icon,
.products-list[data-layout='list']
	.product-card
	.product-card__compare.btn-svg-icon,
.products-list[data-layout='list']
	.product-card
	.product-card__wishlist.btn-svg-icon {
	width: calc(1.875rem + 2px);
}
.products-list[data-layout='list'] .product-card .product-card__availability {
	color: #999;
	font-size: 14px;
	padding-right: 10px;
}
.products-list[data-layout='list'] .product-card .product-card__features-list {
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 0;
}
.products-list[data-layout='list']
	.product-card
	.product-card__features-list
	li {
	padding: 3px 0 3px 12px;
}
.products-list[data-layout='list']
	.product-card
	.product-card__features-list
	li:before {
	top: 8px;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.products-list[data-layout='list'] .product-card .product-card__image {
		width: 180px;
		padding-left: 20px;
		padding-right: 20px;
	}
	.products-list[data-layout='list'] .product-card .product-card__info {
		padding-right: 20px;
		padding-left: 0;
	}
}
@media (max-width: 767px) {
	.products-list[data-layout='list'] .product-card {
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.products-list[data-layout='list'] .product-card .product-card__image {
		width: 250px;
		padding: 20px;
		margin: 0 auto;
	}
	.products-list[data-layout='list'] .product-card .product-card__info {
		border-top: 1px solid #ebebeb;
		padding: 20px;
	}
	.products-list[data-layout='list'] .product-card .product-card__actions {
		width: auto;
		border-left: none;
		border-top: 1px solid #ebebeb;
		padding: 20px;
	}
	.products-list[data-layout='list'] .product-card .product-card__buttons {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
	.products-list[data-layout='list'] .product-card .product-card__buttons .btn {
		font-size: 1rem;
		height: calc(2.25rem + 2px);
		line-height: 1.5;
		padding: 0.375rem 1.25rem;
		font-weight: 500;
	}
	.products-list[data-layout='list']
		.product-card
		.product-card__buttons
		.btn.btn-svg-icon {
		width: calc(2.25rem + 2px);
	}
	.products-list[data-layout='list']
		.product-card
		.product-card__addtocart--list {
		width: auto;
		margin-right: auto;
	}
}
.products-view__options {
	padding-bottom: 20px;
}
.products-view__pagination {
	padding-top: 32px;
}
.quickview {
	padding: 60px;
	position: relative;
}
.quickview__close {
	position: absolute;
	right: 0;
	top: 0;
	width: 50px;
	height: 50px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	cursor: pointer;
	background: #fff;
	border-radius: 3px;
	border: none;
	fill: #bfbfbf;
	transition: all 0.2s;
	z-index: 2;
}
.quickview__close:focus,
.quickview__close:hover {
	fill: gray;
}
.quickview__close:focus {
	outline: none;
}
@media (min-width: 576px) and (max-width: 1199px) {
	.quickview {
		padding: 30px;
	}
}
@media (max-width: 575px) {
	.quickview {
		padding: 20px;
	}
}
.review {
	display: -ms-flexbox;
	display: flex;
}
.review__avatar {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-left: 16px;
	margin-right: 24px;
}
.review__avatar img {
	width: 70px;
	border-radius: 1000px;
}
.review__author {
	margin-top: -4px;
	font-size: 16px;
	font-weight: 500;
}
.review__rating {
	margin-top: 2px;
}
.review__text {
	font-size: 16px;
	margin-top: 12px;
}
.review__date {
	margin-top: 10px;
	color: #999;
	font-size: 14px;
}
@media (min-width: 576px) and (max-width: 767px) {
	.review__avatar {
		margin-right: 18px;
	}
	.review__avatar img {
		width: 60px;
	}
}
@media (max-width: 575px) {
	.review__avatar {
		display: none;
	}
}
.reviews-list__content {
	list-style: none;
	padding: 0;
	margin: 0;
}
.reviews-list__item {
	border-bottom: 1px solid #ebebeb;
	padding-top: 28px;
	padding-bottom: 24px;
}
.reviews-list__item:first-child {
	padding-top: 0;
}
.reviews-list__pagination {
	margin-top: 36px;
}
.reviews-view__header {
	margin-bottom: 50px;
}
.reviews-view__form {
	margin-top: 40px;
	border-top: 1px solid #ebebeb;
	padding-top: 60px;
}
@media (max-width: 575px) {
	.reviews-view__header {
		margin-bottom: 40px;
	}
	.reviews-view__form {
		margin-top: 30px;
		padding-top: 50px;
	}
}
.shop-layout {
	display: -ms-flexbox;
	display: flex;
}
@media (min-width: 992px) {
	.shop-layout__sidebar {
		width: 255px;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
	.shop-layout__content {
		width: calc(100% - 285px);
	}
	.shop-layout--sidebar--start .shop-layout__sidebar {
		margin-right: 30px;
	}
	.shop-layout--sidebar--end .shop-layout__sidebar {
		margin-left: 30px;
	}
}
@media (max-width: 991px) {
	.shop-layout {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.shop-layout__content,
	.shop-layout__sidebar {
		width: 100%;
	}
	.shop-layout__sidebar {
		-ms-flex-order: 1;
		order: 1;
	}
	.shop-layout--sidebar--end .shop-layout__sidebar,
	.shop-layout--sidebar--start .shop-layout__sidebar {
		margin-left: 0;
		margin-right: 0;
	}
}
.spec {
	font-size: 15px;
	line-height: 18px;
}
.spec__header {
	margin-bottom: 50px;
}
.spec__section + .spec__section {
	margin-top: 40px;
}
.spec__section-title {
	font-weight: 500;
	font-size: 18px;
	letter-spacing: -0.03em;
	line-height: 20px;
	margin: 0 0 12px;
}
.spec__row {
	padding: 8px 12px;
	display: -ms-flexbox;
	display: flex;
	border-bottom: 1px solid #ebebeb;
}
.spec__row:first-of-type {
	border-top: 1px solid #ebebeb;
}
.spec__name {
	width: 230px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	padding-right: 30px;
	color: #999;
}
.spec__disclaimer {
	margin-top: 40px;
	font-size: 14px;
	line-height: 22px;
	color: #999;
}
@media (min-width: 576px) and (max-width: 767px) {
	.spec__name {
		width: 170px;
	}
}
@media (max-width: 575px) {
	.spec__row {
		display: block;
		padding: 12px 0;
	}
	.spec__name {
		width: auto;
		margin-bottom: 6px;
	}
}
.view-options {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-size: 15px;
}
.view-options__legend {
	margin-left: 20px;
}
.view-options__divider {
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.view-options__control {
	margin-left: 24px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
.view-options__control label {
	margin-bottom: 0;
	margin-right: 8px;
}
@media (max-width: 767px) {
	.view-options {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.view-options__legend {
		margin-left: auto;
	}
	.view-options__divider {
		width: 100%;
		height: 12px;
	}
	.view-options__control {
		margin-left: auto;
	}
	.view-options__control + .view-options__control {
		margin-left: 24px;
	}
}
@media (max-width: 419px) {
	.view-options__legend {
		width: 100%;
		padding-top: 12px;
	}
	.view-options__divider {
		height: 8px;
	}
	.view-options__control {
		display: block;
		margin-left: 0;
		margin-right: 24px;
	}
	.view-options__control + .view-options__control {
		margin-left: 0;
	}
}
.wishlist__product-name {
	color: inherit;
}
.wishlist__product-rating {
	margin-top: 6px;
	display: -ms-flexbox;
	display: flex;
}
.wishlist__product-rating-legend {
	font-size: 13px;
	line-height: 1;
	color: #b3b3b3;
	margin-left: 9px;
	padding-top: 1px;
}
@media (min-width: 768px) {
	.wishlist {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
	}
	.wishlist__column {
		padding: 12px 18px;
		border-top: 1px solid #ebebeb;
	}
	.wishlist__column:first-child {
		border-left: 1px solid #ebebeb;
	}
	.wishlist__column:last-child {
		border-right: 1px solid #ebebeb;
	}
	.wishlist__head {
		background: #f7f7f7;
		font-size: 15px;
	}
	.wishlist__head .wishlist__column {
		font-weight: 500;
	}
	.wishlist__head .wishlist__column:first-child {
		border-top-left-radius: 2.5px;
	}
	.wishlist__head .wishlist__column:last-child {
		border-top-right-radius: 2.5px;
	}
	.wishlist__body .wishlist__column {
		padding-top: 18px;
		padding-bottom: 18px;
	}
	.wishlist__body .wishlist__row:last-child .wishlist__column {
		border-bottom: 1px solid #ebebeb;
	}
	.wishlist__body .wishlist__row:last-child .wishlist__column:first-child {
		border-bottom-left-radius: 2.5px;
	}
	.wishlist__body .wishlist__row:last-child .wishlist__column:last-child {
		border-bottom-right-radius: 2.5px;
	}
	.wishlist__column--image {
		width: 1px;
		text-align: center;
	}
	.wishlist__column--image img {
		width: 80px;
	}
	.wishlist__column--stock {
		white-space: nowrap;
		text-align: center;
		width: 1px;
	}
	.wishlist__column--price {
		text-align: right;
		width: 170px;
		padding-left: 90px;
	}
	.wishlist__column--tocart {
		white-space: nowrap;
		width: 1px;
		padding-left: 90px;
		padding-right: 0;
	}
	.wishlist__column--remove {
		width: 1px;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.wishlist__column--price {
		width: 140px;
	}
	.wishlist__column--price,
	.wishlist__column--tocart {
		padding-left: 30px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.wishlist__column--price {
		width: 1px;
	}
	.wishlist__column--price,
	.wishlist__column--product,
	.wishlist__column--stock,
	.wishlist__column--tocart {
		padding-left: 0;
	}
	.wishlist__column--remove {
		padding-left: 8px;
		padding-right: 12px;
	}
}
@media (max-width: 767px) {
	.wishlist {
		display: block;
	}
	.wishlist__head {
		display: none;
	}
	.wishlist__body {
		display: block;
	}
	.wishlist__row {
		position: relative;
		width: 100%;
		display: block;
		border: 1px solid #ebebeb;
		border-radius: 2px;
		padding: 20px 20px 24px;
	}
	.wishlist__row + .wishlist__row {
		margin-top: 15px;
	}
	.wishlist__column {
		display: block;
		padding: 0;
	}
	.wishlist__column--image {
		padding: 20px 20px 10px;
		text-align: center;
	}
	.wishlist__column--image img {
		width: 100%;
		max-width: 160px;
	}
	.wishlist__column--price,
	.wishlist__column--product,
	.wishlist__column--stock,
	.wishlist__column--tocart {
		text-align: center;
	}
	.wishlist__column--stock {
		margin-top: 8px;
	}
	.wishlist__column--price {
		margin-top: 12px;
		font-size: 16px;
		font-weight: 700;
	}
	.wishlist__column--tocart {
		margin-top: 24px;
	}
	.wishlist__column--remove {
		position: absolute;
		top: 5px;
		right: 5px;
	}
	.wishlist__product-rating {
		-ms-flex-pack: center;
		justify-content: center;
	}
}
.comment {
	display: -ms-flexbox;
	display: flex;
}
.comment__avatar {
	width: 70px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-left: 20px;
	margin-right: 24px;
}
.comment__avatar img {
	max-width: 100%;
	border-radius: 222px;
}
.comment__content {
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.comment__header {
	margin-top: -4px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-align: center;
	align-items: center;
}
.comment__author {
	font-size: 16px;
	font-weight: 500;
}
.comment__author a {
	color: inherit;
	transition: color 0.2s;
}
.comment__author a:hover {
	color: #1b68a4;
}
.comment__reply .btn {
	height: 22px;
	padding: 0.125rem 0.375rem;
	display: block;
}
.comment__text {
	font-size: 16px;
	margin-top: 6px;
}
.comment__date {
	margin-top: 10px;
	color: #999;
	font-size: 14px;
}
@media (max-width: 767px) {
	.comment__avatar {
		margin-left: 0;
	}
}
@media (max-width: 575px) and (max-width: 575px) {
	.comment__avatar {
		display: none;
	}
}
.comments-list {
	list-style: none;
	margin: 0;
	padding: 0;
}
.comments-list__item + .comments-list__item {
	border-top: 1px solid #ebebeb;
	margin-top: 24px;
	padding-top: 28px;
}
.comments-list--level--0 > .comments-list__item:last-child {
	padding-bottom: 28px;
	border-bottom: 1px solid #ebebeb;
}
.comments-list--level--1 {
	border-top: 1px solid #ebebeb;
	margin-top: 24px;
	padding-top: 28px;
}
.comments-list--level--1 > .comments-list__item {
	margin-left: 46px;
}
@media (max-width: 767px) {
	.comments-list--level--1,
	.comments-list__item + .comments-list__item {
		margin-top: 20px;
		padding-top: 24px;
	}
}
.post__featured {
	text-align: center;
	position: relative;
	overflow: hidden;
	border-radius: 2px;
	margin-bottom: 40px;
}
.post__featured img {
	transition: opacity 0.2s;
	max-width: 100%;
}
.post__featured:hover img {
	opacity: 0.8;
}
.post__footer {
	margin-top: 60px;
}
.post__tags-share-links {
	margin-bottom: 30px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.post__tags {
	margin-right: 30px;
}
.post__share-links {
	padding-top: 2px;
}
.post__section {
	margin-top: 70px;
}
.post__section-title {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 28px;
	font-size: 22px;
}
.post__section-title:after {
	margin-left: 16px;
	margin-top: 1px;
	content: '';
	display: block;
	height: 2px;
	-ms-flex-positive: 1;
	flex-grow: 1;
	background: #ebebeb;
}
@media (max-width: 1199px) {
	.post__section {
		margin-top: 50px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.post__featured {
		margin-bottom: 30px;
	}
}
@media (max-width: 767px) {
	.post__featured {
		margin-bottom: 24px;
	}
	.post__footer {
		margin-top: 40px;
	}
	.post__tags-share-links {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-align: center;
		align-items: center;
	}
	.post__tags {
		margin-right: 0;
	}
	.post__tags .tags__list {
		-ms-flex-pack: center;
		justify-content: center;
	}
	.post__share-links {
		padding: 20px 0 0;
	}
}
@media (min-width: 992px) {
	.post--layout--full .post__featured {
		margin-bottom: 50px;
	}
}
@media (min-width: 1200px) {
	.post--layout--full .post__featured {
		left: -190px;
		width: calc(100% + 380px);
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.post--layout--full .post__featured {
		left: -120px;
		width: calc(100% + 240px);
	}
}
.post-author {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
	padding: 22px;
	display: -ms-flexbox;
	display: flex;
}
.post-author__avatar {
	width: 70px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	overflow: hidden;
	border-radius: 2px;
	margin-right: 18px;
}
.post-author__avatar img {
	max-width: 100%;
}
.post-author__name {
	margin-top: 1px;
	font-size: 15px;
	font-weight: 500;
}
.post-author__name a {
	color: inherit;
}
.post-author__about {
	margin-top: 4px;
	font-size: 14px;
}
.post__related-posts {
	margin-top: 50px;
}
@media (max-width: 575px) {
	.post-author {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}
	.post-author__avatar {
		margin-right: 0;
		width: 90px;
	}
	.post-author__info {
		margin-top: 16px;
	}
	.post-author__about {
		margin-top: 8px;
	}
}
.post-header__categories {
	display: -ms-flexbox;
	display: flex;
}
.post-header__categories a {
	padding: 5px 7px 4px;
	display: block;
	font-size: 11px;
	line-height: 1;
	font-weight: 500;
	text-transform: uppercase;
	color: gray;
	background: #ebebeb;
	border-radius: 1.5px;
}
.post-header__meta {
	font-size: 14px;
	letter-spacing: 0.02em;
	color: #b2b2b2;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.post-header__meta a {
	color: inherit;
	transition: color 0.2s;
}
.post-header__meta a:hover {
	color: #1b68a4;
}
.post-header__meta-item {
	position: relative;
	margin-right: 20px;
}
.post-header__meta-item:after {
	position: absolute;
	display: block;
	content: '';
	background: currentColor;
	width: 4px;
	height: 4px;
	border-radius: 2px;
	right: -12px;
	top: 8px;
}
.post-header__meta-item:last-child {
	margin-right: 0;
}
.post-header__meta-item:last-child:after {
	display: none;
}
.post-header--layout--classic .post-header__categories {
	margin-bottom: 12px;
}
.post-header--layout--classic .post-header__title {
	line-height: 38px;
	margin-bottom: 8px;
}
.post-header--layout--classic .post-header__meta {
	margin-bottom: 30px;
}
@media (max-width: 1199px) {
	.post-header--layout--classic .post-header__title {
		margin-bottom: 6px;
	}
}
@media (min-width: 768px) and (max-width: 1199px) {
	.post-header--layout--classic .post-header__meta {
		margin-bottom: 24px;
	}
}
@media (max-width: 767px) {
	.post-header--layout--classic .post-header__meta {
		margin-bottom: 20px;
	}
}
@media (min-width: 480px) and (max-width: 767px) {
	.post-header--layout--classic .post-header__title {
		font-size: 30px;
		line-height: 32px;
	}
}
@media (max-width: 479px) {
	.post-header--layout--classic .post-header__title {
		font-size: 28px;
		line-height: 30px;
	}
}
.post-header--layout--full {
	width: 640px;
	max-width: 100%;
	margin: 0 auto;
	text-align: center;
	padding: 20px 0 50px;
}
.post-header--layout--full .post-header__categories {
	-ms-flex-pack: center;
	justify-content: center;
	margin-bottom: 24px;
}
.post-header--layout--full .post-header__title {
	line-height: 42px;
}
.post-header--layout--full .post-header__meta {
	margin-top: 24px;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 767px) {
	.post-header--layout--full {
		padding: 10px 0 24px;
	}
	.post-header--layout--full .post-header__categories {
		margin-bottom: 16px;
	}
	.post-header--layout--full .post-header__title {
		font-size: 30px;
		line-height: 32px;
	}
	.post-header--layout--full .post-header__meta {
		margin-top: 12px;
	}
}
@media (max-width: 575px) {
	.post-header--layout--full {
		padding-top: 0;
		text-align: left;
	}
	.post-header--layout--full .post-header__categories,
	.post-header--layout--full .post-header__meta {
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.post-header--layout--full .post-header__meta {
		margin-top: 8px;
	}
}
.posts-list__body {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: -32px -15px;
}
.posts-list__item {
	margin: 32px 15px;
}
.posts-list--layout--classic .posts-list__item {
	width: 100%;
}
@media (max-width: 767px) {
	.posts-list--layout--classic .posts-list__body,
	.posts-list--layout--classic .posts-list__item {
		margin: 0;
	}
	.posts-list--layout--classic .posts-list__item + .posts-list__item {
		margin-top: 36px;
	}
}
@media (max-width: 479px) {
	.posts-list--layout--classic .posts-list__item + .posts-list__item {
		margin-top: 30px;
	}
}
.posts-list--layout--list .posts-list__body {
	margin: -20px 0;
}
.posts-list--layout--list .posts-list__item {
	width: 100%;
	margin: 20px 0;
}
.posts-list--layout--grid2 .posts-list__item {
	width: calc(50% - 30px);
}
@media (max-width: 767px) {
	.posts-list--layout--grid2 .posts-list__body {
		margin: 0;
	}
	.posts-list--layout--grid2 .posts-list__item {
		margin: 0;
		width: 100%;
	}
	.posts-list--layout--grid2 .posts-list__item + .posts-list__item {
		margin-top: 36px;
	}
}
.posts-view__pagination {
	padding-top: 52px;
}
.related-posts__list {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: -15px;
}
.related-posts__item {
	width: calc(50% - 30px);
	margin: 15px;
}
@media (max-width: 479px) {
	.related-posts__item {
		width: calc(100% - 30px);
	}
}
.block {
	margin-bottom: 70px;
}
.block--highlighted {
	padding: 50px 0 60px;
	background: #f9f9f9;
}
@media (max-width: 991px) {
	.block {
		margin-bottom: 40px;
	}
}
.block-banner__body {
	position: relative;
	display: block;
	height: 170px;
	text-align: center;
	color: inherit;
}
.block-banner__body:hover {
	color: inherit;
}
.block-banner__image {
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: 50%;
	z-index: -1;
}
.block-banner__image--mobile {
	display: none;
}
.block-banner__title {
	padding-top: 28px;
	font-size: 30px;
	line-height: 1;
	font-weight: 700;
}
.block-banner__text {
	padding-top: 8px;
}
.block-banner__button {
	padding-top: 20px;
}
.block-banner__mobile-br {
	display: none;
}
@media (max-width: 767px) {
	.block-banner__body {
		height: 390px;
	}
	.block-banner__image--desktop {
		display: none;
	}
	.block-banner__image--mobile {
		display: block;
	}
	.block-banner__button,
	.block-banner__text,
	.block-banner__title {
		width: 220px;
		margin-left: auto;
		margin-right: auto;
	}
	.block-banner__title {
		padding-top: 48px;
		line-height: 1.1;
	}
	.block-banner__text {
		padding-top: 12px;
	}
	.block-banner__button {
		padding-top: 28px;
	}
	.block-banner__mobile-br {
		display: block;
	}
}
.block-brands__slider {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
}
.block-brands__item {
	padding: 36px 24px;
}
.block-categories__list {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: -6px;
}
.block-categories__item {
	margin: 6px;
	width: calc(33.3333% - 12px);
}
@media (min-width: 480px) and (max-width: 767px) {
	.block-categories--layout--classic .block-categories__item {
		width: calc(50% - 12px);
	}
}
@media (max-width: 479px) {
	.block-categories--layout--classic .block-categories__item {
		width: calc(100% - 12px);
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.block-categories--layout--compact .block-categories__item {
		width: calc(50% - 12px);
	}
}
@media (max-width: 767px) {
	.block-categories--layout--compact .block-categories__item {
		width: calc(100% - 12px);
	}
}
.block-features__list {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
	display: -ms-flexbox;
	display: flex;
}
.block-features__item {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	padding: 16px 24px;
	display: -ms-flexbox;
	display: flex;
}
.block-features__divider,
.block-features__icon {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.block-features__icon {
	width: 48px;
	height: 48px;
	margin-right: 20px;
	fill: #1b68a4;
}
.block-features__icon svg {
	display: block;
}
.block-features__content {
	padding-top: 2px;
}
.block-features__title {
	font-size: 17px;
	line-height: 24px;
	font-weight: 700;
}
.block-features__subtitle {
	font-size: 14px;
}
.block-features--layout--boxed {
	margin-top: -56px;
}
.block-features--layout--boxed .block-features__list {
	border: none;
}
.block-features--layout--boxed .block-features__item {
	-ms-flex-direction: column;
	flex-direction: column;
	background: #f7f7f7;
	border-radius: 3px;
	text-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 30px 28px 28px;
}
.block-features--layout--boxed .block-features__divider {
	width: 14px;
}
.block-features--layout--boxed .block-features__icon {
	margin-right: 0;
	margin-bottom: 12px;
}
@media (min-width: 480px) and (max-width: 991px) {
	.block-features--layout--boxed .block-features__list {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.block-features--layout--boxed .block-features__item {
		width: 50%;
	}
	.block-features--layout--boxed .block-features__divider:nth-child(4n) {
		width: 100%;
		height: 14px;
	}
}
@media (max-width: 479px) {
	.block-features--layout--boxed .block-features__list {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.block-features--layout--boxed .block-features__item {
		width: 100%;
	}
	.block-features--layout--boxed .block-features__divider {
		width: 100%;
		height: 14px;
	}
}
.block-features--layout--classic {
	margin-top: -40px;
}
.block-features--layout--classic .block-features__divider {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 2px;
	background: #f0f0f0;
}
@media (max-width: 767px) and (min-width: 576px),
	(max-width: 1199px) and (min-width: 992px) {
	.block-features--layout--classic .block-features__item {
		padding: 12px;
	}
	.block-features--layout--classic .block-features__icon {
		margin-right: 16px;
	}
	.block-features--layout--classic .block-features__content {
		padding-top: 0;
	}
}
@media (max-width: 991px) and (min-width: 420px) {
	.block-features--layout--classic .block-features__list {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.block-features--layout--classic .block-features__item {
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
		width: calc(50% - 1px);
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
	.block-features--layout--classic .block-features__divider:nth-child(4n) {
		width: 100%;
		height: 2px;
	}
}
@media (max-width: 575px) and (min-width: 420px) {
	.block-features--layout--classic .block-features__item {
		text-align: center;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.block-features--layout--classic .block-features__icon {
		margin-right: 0;
		margin-bottom: 20px;
		width: 100%;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
@media (max-width: 419px) {
	.block-features--layout--classic .block-features__list {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.block-features--layout--classic .block-features__item {
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
		width: 100%;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
	.block-features--layout--classic .block-features__icon {
		margin-right: 24px;
	}
	.block-features--layout--classic .block-features__divider {
		width: 100%;
		height: 2px;
	}
}
.block-map {
	margin-bottom: 10px;
}
.block-map__body {
	height: 540px;
	position: relative;
}
.block-map__body:before {
	top: 0;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.15), transparent);
}
.block-map__body:after,
.block-map__body:before {
	position: absolute;
	content: '';
	display: block;
	left: 0;
	width: 100%;
	height: 3px;
	z-index: 1;
	pointer-events: none;
}
.block-map__body:after {
	bottom: 0;
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.05), transparent);
}
.block-map__body iframe {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
@media (max-width: 1199px) {
	.block-map__body {
		height: 500px;
	}
}
@media (max-width: 991px) {
	.block-map__body {
		height: 440px;
	}
}
@media (max-width: 767px) {
	.block-map__body {
		height: 380px;
	}
}
@media (max-width: 575px) {
	.block-map__body {
		height: 300px;
	}
}
@media (min-width: 576px) {
	.block-posts--layout--list-sm .post-card {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: row;
		flex-direction: row;
		-ms-flex-align: start;
		align-items: flex-start;
	}
	.block-posts--layout--list-sm .post-card .post-card__image {
		width: 240px;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		margin-right: 22px;
	}
	.block-posts--layout--list-sm .post-card .post-card__name {
		margin-top: 10px;
		font-size: 16px;
		line-height: 20px;
		-ms-flex-order: -1;
		order: -1;
		height: 40px;
		overflow: hidden;
	}
	.block-posts--layout--list-sm .post-card .post-card__date {
		margin-top: 6px;
	}
	.block-posts--layout--list-sm .post-card .post-card__category {
		display: none;
	}
	.block-posts--layout--list-sm .post-card .post-card__content {
		margin-top: 10px;
		font-size: 15px;
		line-height: 22px;
		height: 66px;
		overflow: hidden;
	}
	.block-posts--layout--list-sm .post-card .post-card__read-more {
		display: none;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.block-posts--layout--list-sm .post-card .post-card__image {
		width: 210px;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		margin-right: 18px;
	}
	.block-posts--layout--list-sm .post-card .post-card__name {
		margin-top: 5px;
		font-size: 15px;
		line-height: 19px;
		-ms-flex-order: -1;
		order: -1;
	}
	.block-posts--layout--list-sm .post-card .post-card__date {
		margin-top: 4px;
	}
	.block-posts--layout--list-sm .post-card .post-card__category {
		display: none;
	}
	.block-posts--layout--list-sm .post-card .post-card__content {
		margin-top: 10px;
		font-size: 15px;
		line-height: 20px;
		height: 60px;
		overflow: hidden;
	}
	.block-posts--layout--list-sm .post-card .post-card__read-more {
		display: none;
	}
}
@media (max-width: 575px) {
	.block-posts--layout--list-sm .post-card .post-card__info {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.block-posts--layout--list-sm .post-card .post-card__image {
		margin-bottom: 14px;
	}
	.block-posts--layout--list-sm .post-card .post-card__category {
		display: none;
	}
	.block-posts--layout--list-sm .post-card .post-card__date {
		-ms-flex-order: -1;
		order: -1;
	}
	.block-posts--layout--list-sm .post-card .post-card__name {
		width: 100%;
		font-size: 18px;
		margin-top: 4px;
		line-height: 22px;
	}
	.block-posts--layout--list-sm .post-card .post-card__content {
		width: 100%;
		margin-top: 12px;
	}
	.block-posts--layout--list-sm .post-card .post-card__read-more {
		width: 100%;
		display: none;
	}
}
.block-posts--layout--grid-nl .post-card .post-card__info {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.block-posts--layout--grid-nl .post-card .post-card__image {
	margin-bottom: 14px;
}
.block-posts--layout--grid-nl .post-card .post-card__category {
	display: none;
}
.block-posts--layout--grid-nl .post-card .post-card__date {
	-ms-flex-order: -1;
	order: -1;
}
.block-posts--layout--grid-nl .post-card .post-card__name {
	width: 100%;
	font-size: 18px;
	margin-top: 4px;
	line-height: 22px;
}
.block-posts--layout--grid-nl .post-card .post-card__content {
	width: 100%;
	margin-top: 12px;
}
.block-posts--layout--grid-nl .post-card .post-card__read-more {
	width: 100%;
	display: none;
}
.block-product-columns__item {
	position: relative;
}
.block-product-columns__item + .block-product-columns__item {
	margin-top: -1px;
}
.block-product-columns__item:hover {
	z-index: 2;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.block-product-columns .product-card .product-card__image {
		width: 110px;
	}
	.block-product-columns .product-card .product-card__rating {
		display: none;
	}
	.block-product-columns .product-card .product-card__actions {
		left: 110px;
	}
}
.block-products__body,
.block-products__featured {
	display: -ms-flexbox;
	display: flex;
}
.block-products__featured {
	-ms-flex-direction: column;
	flex-direction: column;
	width: 36%;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.block-products__featured-item {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.block-products__featured-item .product-card {
	width: 100%;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.block-products__list {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: -6px;
}
.block-products__list-item {
	display: -ms-flexbox;
	display: flex;
	width: calc(33.3333% - 12px);
	margin: 6px;
}
.block-products__list-item .product-card {
	width: 100%;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.block-products__list-item .product-card .product-card__image {
	padding: 18px 18px 20px;
}
.block-products__list-item .product-card .product-card__info {
	padding: 0 24px;
}
.block-products__list-item .product-card .product-card__actions {
	padding: 0 24px 24px;
}
.block-products__list-item .product-card .product-card__availability,
.block-products__list-item .product-card .product-card__description,
.block-products__list-item .product-card .product-card__features-list {
	display: none;
}
.block-products__list-item .product-card .product-card__image {
	padding: 15px;
}
.block-products__list-item .product-card .product-card__badges-list {
	left: 16px;
	top: 16px;
}
.block-products__list-item .product-card .product-card__info {
	padding: 0 15px;
}
.block-products__list-item .product-card .product-card__actions {
	padding: 0 15px 15px;
}
.block-products__list-item .product-card .product-card__buttons .btn {
	font-size: 0.8125rem;
	height: calc(1.5rem + 2px);
	line-height: 1.25;
	padding: 0.25rem 0.5625rem;
	font-weight: 500;
}
.block-products__list-item
	.product-card
	.product-card__buttons
	.btn.btn-svg-icon {
	width: calc(1.5rem + 2px);
}
@media (hover: hover) {
	.block-products__list-item .product-card .product-card__buttons {
		display: none;
	}
	.block-products__list-item .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.block-products__list-item .product-card:hover .product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
	.block-products__list-item .product-card .product-card__buttons {
		display: none;
	}
	.block-products__list-item .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.block-products__list-item .product-card:hover .product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (min-width: 992px) {
	.block-products__featured-item .product-card .product-card__image {
		padding: 18px 18px 20px;
	}
	.block-products__featured-item .product-card .product-card__info {
		padding: 0 24px;
	}
	.block-products__featured-item .product-card .product-card__actions {
		padding: 0 24px 24px;
	}
	.block-products__featured-item .product-card .product-card__availability,
	.block-products__featured-item .product-card .product-card__description,
	.block-products__featured-item .product-card .product-card__features-list {
		display: none;
	}
	.block-products__featured-item .product-card .product-card__image {
		padding: 25px;
	}
	.block-products__featured-item .product-card .product-card__info {
		padding: 0 30px;
		-ms-flex-positive: 0;
		flex-grow: 0;
	}
	.block-products__featured-item .product-card .product-card__name {
		font-size: 17px;
		line-height: 23px;
	}
	.block-products__featured-item .product-card .product-card__actions {
		padding: 0 30px 30px;
		-ms-flex-positive: 1;
		flex-grow: 1;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.block-products__featured-item .product-card .product-card__rating {
		margin-top: 10px;
	}
	.block-products__featured-item .product-card .product-card__prices {
		margin-bottom: auto;
		font-size: 20px;
	}
	.block-products__featured + .block-products__list {
		margin-left: 6px;
	}
	.block-products__list + .block-products__featured {
		margin-left: 12px;
	}
}
@media (min-width: 576px) and (max-width: 991px) {
	.block-products__featured-item {
		display: block;
	}
	.block-products__featured-item .product-card {
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.block-products__featured-item .product-card .product-card__image {
		width: 120px;
		padding: 16px;
	}
	.block-products__featured-item .product-card .product-card__badges-list {
		left: 10px;
		top: 10px;
	}
	.block-products__featured-item .product-card .product-card__badge {
		padding: 3px 5px 2px;
	}
	.block-products__featured-item .product-card .product-card__info {
		padding: 14px 30px 42px 0;
		width: 100%;
	}
	.block-products__featured-item .product-card .product-card__availability,
	.block-products__featured-item .product-card .product-card__buttons,
	.block-products__featured-item .product-card .product-card__description {
		display: none;
	}
	.block-products__featured-item .product-card .product-card__actions {
		position: absolute;
		left: 120px;
		bottom: 18px;
	}
	.block-products__featured-item .product-card .product-card__features-list {
		display: none;
	}
	.block-products__featured-item .product-card .product-card__image {
		width: 216px;
		padding-left: 24px;
		padding-right: 24px;
		margin-right: 16px;
	}
	.block-products__featured-item .product-card .product-card__info {
		padding-top: 20px;
		padding-bottom: 120px;
	}
	.block-products__featured-item .product-card .product-card__name {
		font-size: 17px;
		margin-bottom: 2px;
		-ms-flex-positive: 0;
		flex-grow: 0;
	}
	.block-products__featured-item .product-card .product-card__actions {
		-ms-flex-align: center;
		align-items: center;
		left: 232px;
		bottom: 26px;
	}
	.block-products__featured-item .product-card .product-card__prices {
		margin-top: 0;
		margin-right: 24px;
		font-size: 20px;
	}
	.block-products__featured-item .product-card .product-card__buttons {
		display: block;
	}
}
@media (max-width: 991px) {
	.block-products__body {
		display: block;
	}
	.block-products__featured {
		width: 100%;
	}
	.block-products__featured + .block-products__list {
		margin-top: 6px;
	}
	.block-products__list + .block-products__featured {
		margin-top: 12px;
	}
}
@media (max-width: 767px) {
	.block-products__list-item {
		width: calc(50% - 12px);
	}
}
@media (max-width: 575px) {
	.block-products__featured-item .product-card .product-card__image {
		padding: 18px 18px 20px;
	}
	.block-products__featured-item .product-card .product-card__info {
		padding: 0 24px;
	}
	.block-products__featured-item .product-card .product-card__actions {
		padding: 0 24px 24px;
	}
	.block-products__featured-item .product-card .product-card__availability,
	.block-products__featured-item .product-card .product-card__description,
	.block-products__featured-item .product-card .product-card__features-list {
		display: none;
	}
	.block-products__featured-item .product-card .product-card__buttons .btn {
		font-size: 0.875rem;
		height: calc(1.875rem + 2px);
		line-height: 1.25;
		padding: 0.375rem 1rem;
		font-weight: 500;
	}
	.block-products__featured-item
		.product-card
		.product-card__buttons
		.btn.btn-svg-icon {
		width: calc(1.875rem + 2px);
	}
}
@media (max-width: 575px) and (hover: hover) {
	.block-products__featured-item .product-card .product-card__buttons {
		display: none;
	}
	.block-products__featured-item .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -56px;
	}
	.block-products__featured-item .product-card:hover .product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (max-width: 575px) and (-ms-high-contrast: none),
	screen and (max-width: 575px) and (-ms-high-contrast: active) {
	.block-products__featured-item .product-card .product-card__buttons {
		display: none;
	}
	.block-products__featured-item .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -56px;
	}
	.block-products__featured-item .product-card:hover .product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (max-width: 479px) {
	.block-products__list-item {
		width: calc(100% - 12px);
	}
}
.block-products-carousel .owl-stage {
	padding-bottom: 56px;
}
.block-products-carousel .owl-stage-outer {
	margin-bottom: -56px;
}
.block-products-carousel__slider {
	position: relative;
}
.block-products-carousel__preloader {
	position: absolute;
	z-index: 2;
	left: 0;
	top: 0;
	right: 0;
	bottom: 56px;
	background: hsla(0, 0%, 100%, 0.9);
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s, visibility 0s 0.3s;
}
.block-products-carousel__preloader:after {
	display: block;
	content: '';
	position: absolute;
	border: 2px solid rgba(0, 0, 0, 0.1);
	border-top-color: rgba(0, 0, 0, 0.5);
	transition: border-color 0.1s;
	animation-name: a;
	animation-duration: 0.5s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	left: calc(50% - 50px);
	top: calc(50% - 50px);
	width: 100px;
	height: 100px;
	border-radius: 50px;
}
.block-products-carousel--loading .block-products-carousel__preloader {
	transition-delay: 0s, 0s;
	opacity: 1;
	visibility: visible;
}
.block-products-carousel__cell + .block-products-carousel__cell {
	margin-top: 14px;
}
.block-products-carousel[data-layout^='grid-']
	.product-card
	.product-card__image {
	padding: 18px 18px 20px;
}
.block-products-carousel[data-layout^='grid-']
	.product-card
	.product-card__info {
	padding: 0 24px;
}
.block-products-carousel[data-layout^='grid-']
	.product-card
	.product-card__actions {
	padding: 0 24px 24px;
}
.block-products-carousel[data-layout^='grid-']
	.product-card
	.product-card__availability,
.block-products-carousel[data-layout^='grid-']
	.product-card
	.product-card__description,
.block-products-carousel[data-layout^='grid-']
	.product-card
	.product-card__features-list {
	display: none;
}
.block-products-carousel[data-layout='grid-4']
	.product-card
	.product-card__buttons
	.btn {
	font-size: 0.875rem;
	height: calc(1.875rem + 2px);
	line-height: 1.25;
	padding: 0.375rem 1rem;
	font-weight: 500;
}
.block-products-carousel[data-layout='grid-4']
	.product-card
	.product-card__buttons
	.btn.btn-svg-icon {
	width: calc(1.875rem + 2px);
}
@media (hover: hover) {
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.block-products-carousel[data-layout='grid-4'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -56px;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.block-products-carousel[data-layout='grid-4'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -56px;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (min-width: 768px) and (max-width: 1199px) {
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__image {
		padding: 15px;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__badges-list {
		left: 16px;
		top: 16px;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__info {
		padding: 0 15px;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__actions {
		padding: 0 15px 15px;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__buttons
		.btn {
		font-size: 0.8125rem;
		height: calc(1.5rem + 2px);
		line-height: 1.25;
		padding: 0.25rem 0.5625rem;
		font-weight: 500;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__buttons
		.btn.btn-svg-icon {
		width: calc(1.5rem + 2px);
	}
}
@media (min-width: 768px) and (max-width: 1199px) and (hover: hover) {
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.block-products-carousel[data-layout='grid-4'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (min-width: 768px) and (max-width: 1199px) and (-ms-high-contrast: none),
	screen and (min-width: 768px) and (max-width: 1199px) and (-ms-high-contrast: active) {
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.block-products-carousel[data-layout='grid-4'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (max-width: 575px) {
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__image {
		padding: 15px;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__badges-list {
		left: 16px;
		top: 16px;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__info {
		padding: 0 15px;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__actions {
		padding: 0 15px 15px;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__buttons
		.btn {
		font-size: 0.8125rem;
		height: calc(1.5rem + 2px);
		line-height: 1.25;
		padding: 0.25rem 0.5625rem;
		font-weight: 500;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__buttons
		.btn.btn-svg-icon {
		width: calc(1.5rem + 2px);
	}
}
@media (max-width: 575px) and (hover: hover) {
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.block-products-carousel[data-layout='grid-4'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (max-width: 575px) and (-ms-high-contrast: none),
	screen and (max-width: 575px) and (-ms-high-contrast: active) {
	.block-products-carousel[data-layout='grid-4']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.block-products-carousel[data-layout='grid-4'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.block-products-carousel[data-layout='grid-4']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
.block-products-carousel[data-layout='grid-4-sm']
	.product-card
	.product-card__image {
	padding: 15px;
}
.block-products-carousel[data-layout='grid-4-sm']
	.product-card
	.product-card__badges-list {
	left: 16px;
	top: 16px;
}
.block-products-carousel[data-layout='grid-4-sm']
	.product-card
	.product-card__info {
	padding: 0 15px;
}
.block-products-carousel[data-layout='grid-4-sm']
	.product-card
	.product-card__actions {
	padding: 0 15px 15px;
}
.block-products-carousel[data-layout='grid-4-sm']
	.product-card
	.product-card__buttons
	.btn {
	font-size: 0.8125rem;
	height: calc(1.5rem + 2px);
	line-height: 1.25;
	padding: 0.25rem 0.5625rem;
	font-weight: 500;
}
.block-products-carousel[data-layout='grid-4-sm']
	.product-card
	.product-card__buttons
	.btn.btn-svg-icon {
	width: calc(1.5rem + 2px);
}
@media (hover: hover) {
	.block-products-carousel[data-layout='grid-4-sm']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.block-products-carousel[data-layout='grid-4-sm'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.block-products-carousel[data-layout='grid-4-sm']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
	.block-products-carousel[data-layout='grid-4-sm']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.block-products-carousel[data-layout='grid-4-sm'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.block-products-carousel[data-layout='grid-4-sm']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
.block-products-carousel[data-layout='grid-5']
	.product-card
	.product-card__image {
	padding: 15px;
}
.block-products-carousel[data-layout='grid-5']
	.product-card
	.product-card__badges-list {
	left: 16px;
	top: 16px;
}
.block-products-carousel[data-layout='grid-5']
	.product-card
	.product-card__info {
	padding: 0 15px;
}
.block-products-carousel[data-layout='grid-5']
	.product-card
	.product-card__actions {
	padding: 0 15px 15px;
}
.block-products-carousel[data-layout='grid-5']
	.product-card
	.product-card__buttons
	.btn {
	font-size: 0.8125rem;
	height: calc(1.5rem + 2px);
	line-height: 1.25;
	padding: 0.25rem 0.5625rem;
	font-weight: 500;
}
.block-products-carousel[data-layout='grid-5']
	.product-card
	.product-card__buttons
	.btn.btn-svg-icon {
	width: calc(1.5rem + 2px);
}
@media (hover: hover) {
	.block-products-carousel[data-layout='grid-5']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.block-products-carousel[data-layout='grid-5'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.block-products-carousel[data-layout='grid-5']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
	.block-products-carousel[data-layout='grid-5']
		.product-card
		.product-card__buttons {
		display: none;
	}
	.block-products-carousel[data-layout='grid-5'] .product-card:hover {
		position: relative;
		z-index: 3;
		margin-bottom: -50px;
	}
	.block-products-carousel[data-layout='grid-5']
		.product-card:hover
		.product-card__buttons {
		display: -ms-flexbox;
		display: flex;
	}
}
.block-products-carousel[data-layout='horizontal'] .product-card {
	-ms-flex-direction: row;
	flex-direction: row;
}
.block-products-carousel[data-layout='horizontal']
	.product-card
	.product-card__image {
	width: 120px;
	padding: 16px;
}
.block-products-carousel[data-layout='horizontal']
	.product-card
	.product-card__badges-list {
	left: 10px;
	top: 10px;
}
.block-products-carousel[data-layout='horizontal']
	.product-card
	.product-card__badge {
	padding: 3px 5px 2px;
}
.block-products-carousel[data-layout='horizontal']
	.product-card
	.product-card__info {
	padding: 14px 30px 42px 0;
	width: 100%;
}
.block-products-carousel[data-layout='horizontal']
	.product-card
	.product-card__availability,
.block-products-carousel[data-layout='horizontal']
	.product-card
	.product-card__buttons,
.block-products-carousel[data-layout='horizontal']
	.product-card
	.product-card__description {
	display: none;
}
.block-products-carousel[data-layout='horizontal']
	.product-card
	.product-card__actions {
	position: absolute;
	left: 120px;
	bottom: 18px;
}
.block-products-carousel[data-layout='horizontal']
	.product-card
	.product-card__features-list {
	display: none;
}
.block-sidebar__item + .block-sidebar__item {
	margin-top: 30px;
}
.block-sidebar--position--start {
	padding-right: 20px;
}
@media (max-width: 1199px) {
	.block-sidebar--position--start {
		padding-right: 6px;
	}
}
@media (max-width: 991px) {
	.block-sidebar--position--start {
		padding-right: 0;
	}
}
.block-sidebar--position--end {
	padding-left: 20px;
}
@media (max-width: 1199px) {
	.block-sidebar--position--end {
		padding-left: 6px;
	}
}
@media (max-width: 991px) {
	.block-sidebar--position--end {
		padding-left: 0;
	}
}
.block-slideshow {
	margin-bottom: 70px;
	position: relative;
}
.block-slideshow .owl-carousel .owl-dots {
	left: 50%;
	transform: translateX(-50%);
	bottom: 16px;
	position: absolute;
	background: #fff;
	display: -ms-flexbox;
	display: flex;
	padding: 4px;
	border-radius: 9px;
}
.block-slideshow .owl-carousel .owl-dot {
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background: #e0e0e0;
}
.block-slideshow .owl-carousel .owl-dot:focus {
	outline: none;
}
.block-slideshow .owl-carousel .owl-dot:hover {
	background: #d1d1d1;
}
.block-slideshow .owl-carousel .owl-dot.active {
	background: #1b68a4;
}
.block-slideshow .owl-carousel .owl-dot + .owl-dot {
	margin-left: 6px;
}
.block-slideshow__slide {
	position: relative;
	display: block;
	color: inherit;
}
.block-slideshow__slide:hover {
	color: inherit;
}
.block-slideshow__slide-image {
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
}
.block-slideshow__slide-image--mobile {
	display: none;
}
.block-slideshow__slide-content {
	position: absolute;
	left: 46px;
	bottom: 46px;
}
.block-slideshow__slide-title {
	font-size: 30px;
	line-height: 34px;
	font-weight: 700;
	margin-bottom: 12px;
	opacity: 0;
	transition: all 1s 0.2s;
}
.block-slideshow__slide-text {
	line-height: 1.625;
	opacity: 0;
	transform: translateY(15px);
	transition: all 0.8s 0.5s;
}
.block-slideshow__slide-button {
	margin-top: 40px;
	opacity: 0;
	transition: all 1s 0.4s;
}
.block-slideshow .active .block-slideshow__slide-button,
.block-slideshow .active .block-slideshow__slide-text,
.block-slideshow .active .block-slideshow__slide-title {
	opacity: 1;
	transform: none;
}
.block-slideshow--layout--full {
	margin-top: 20px;
}
@media (min-width: 768px) {
	.block-slideshow--layout--full .block-slideshow__body,
	.block-slideshow--layout--full .block-slideshow__slide {
		height: 440px;
	}
	.block-slideshow--layout--full .block-slideshow__slide-content {
		left: 72px;
		bottom: 54px;
	}
	.block-slideshow--layout--full .block-slideshow__slide-title {
		margin-bottom: 16px;
		line-height: 36px;
	}
	.block-slideshow--layout--full .block-slideshow__slide-button {
		margin-top: 48px;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.block-slideshow--layout--full .block-slideshow__slide-image--desktop {
		background-position: -70px top;
	}
	.block-slideshow--layout--full .block-slideshow__slide-content {
		left: 56px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.block-slideshow--layout--full .block-slideshow__slide-image--desktop {
		background-position: -190px top;
	}
	.block-slideshow--layout--full .block-slideshow__slide-content {
		left: 48px;
		bottom: 56px;
	}
	.block-slideshow--layout--full .block-slideshow__slide-title {
		margin-bottom: 8px;
	}
	.block-slideshow--layout--full .block-slideshow__slide-button {
		margin-top: 40px;
	}
}
.block-slideshow--layout--with-departments .block-slideshow__body {
	margin-top: 15px;
	height: 395px;
}
.block-slideshow--layout--with-departments .block-slideshow__slide {
	height: 395px;
}
@media (min-width: 992px) {
	.block-slideshow--layout--with-departments .block-slideshow__body {
		margin-left: -15px;
	}
}
@media (max-width: 991px) {
	.block-slideshow--layout--with-departments
		.block-slideshow__slide-button
		.btn {
		font-size: 0.875rem;
		height: calc(1.875rem + 2px);
		line-height: 1.25;
		padding: 0.375rem 1rem;
		font-weight: 500;
	}
	.block-slideshow--layout--with-departments
		.block-slideshow__slide-button
		.btn.btn-svg-icon {
		width: calc(1.875rem + 2px);
	}
}
@media (max-width: 767px) {
	.block-slideshow__body,
	.block-slideshow__slide {
		height: 395px;
	}
	.block-slideshow__slide-image--mobile {
		background-position: top;
		display: block;
	}
	.block-slideshow__slide-content {
		left: 5%;
		right: 5%;
		top: 30px;
		text-align: center;
	}
	.block-slideshow__slide-title {
		font-size: 26px;
		line-height: 32px;
	}
	.block-slideshow__slide-text {
		display: none;
	}
	.block-slideshow__slide-button {
		margin-top: 24px;
	}
	.block-slideshow__slide-button .btn {
		font-size: 0.875rem;
		height: calc(1.875rem + 2px);
		line-height: 1.25;
		padding: 0.375rem 1rem;
		font-weight: 500;
	}
	.block-slideshow__slide-button .btn.btn-svg-icon {
		width: calc(1.875rem + 2px);
	}
}
.widget__title {
	margin-bottom: 20px;
	font-size: 20px;
}
.widget-aboutus {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
	padding: 28px;
}
.widget-aboutus__socials {
	margin-top: 20px;
	font-size: 16px;
}
.widget-aboutus__socials ul {
	list-style: none;
	padding: 0;
	margin: -4px;
	display: -ms-flexbox;
	display: flex;
}
.widget-aboutus__socials li {
	margin: 4px;
}
.widget-aboutus__link {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 34px;
	height: 34px;
	border-radius: 2px;
	transition: all 0.2s;
	color: #fff;
}
.widget-aboutus__link:hover {
	color: #fff;
	opacity: 0.8;
}
.widget-aboutus__link--facebook {
	background: #3c5a99;
}
.widget-aboutus__link--twitter {
	background: #00a2e8;
}
.widget-aboutus__link--youtube {
	background: #e52e2e;
}
.widget-aboutus__link--instagram {
	background: #815dc7;
}
.widget-aboutus__link--rss {
	background: #ffc338;
}
.widget-categories {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
	padding: 28px;
}
.widget-categories__list {
	list-style: none;
	padding: 0;
	margin: 0;
	line-height: 18px;
	font-size: 16px;
}
.widget-categories__row {
	display: -ms-flexbox;
	display: flex;
}
.widget-categories__row a {
	position: relative;
	display: block;
	color: inherit;
	padding: 7px 10px 7px 16px;
	fill: #ccc;
	transition: color 0.15s;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.widget-categories__row a:hover {
	color: #1b68a4;
}
.widget-categories__arrow {
	position: absolute;
	left: 0;
}
.widget-categories__expander {
	margin-top: 1px;
	width: 28px;
	height: 28px;
	position: relative;
	border: none;
	border-radius: 1.5px;
	cursor: pointer;
	padding: 0;
	margin-right: -10px;
	background: transparent;
	color: #ccc;
	transition: all 0.2s;
}
.widget-categories__expander:focus,
.widget-categories__expander:hover {
	background: #f2f2f2;
	color: #a6a6a6;
}
.widget-categories__expander:focus {
	outline: none;
}
.widget-categories__expander:after,
.widget-categories__expander:before {
	display: block;
	position: absolute;
	content: '';
	background: currentColor;
}
.widget-categories__expander:before {
	width: 8px;
	height: 2px;
	left: calc(50% - 4px);
	top: calc(50% - 1px);
}
.widget-categories__expander:after {
	width: 2px;
	height: 8px;
	left: calc(50% - 1px);
	top: calc(50% - 4px);
	transition: transform 0.2s;
}
.widget-categories__subs {
	overflow: hidden;
	height: 0;
	opacity: 0;
	visibility: hidden;
	font-size: 15px;
	line-height: 26px;
	transition: height 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
.widget-categories__subs ul {
	list-style: none;
	padding: 6px 0 8px;
	margin: 0;
}
.widget-categories__subs li {
	position: relative;
	padding-left: 32px;
}
.widget-categories__subs li:before {
	content: '';
	display: block;
	position: absolute;
	left: 16px;
	top: 10px;
	width: 6px;
	height: 6px;
	border: 1px solid #ccc;
	border-radius: 3px;
}
.widget-categories__subs a {
	color: inherit;
	transition: color 0.15s;
}
.widget-categories__subs a:hover {
	color: #1b68a4;
}
.widget-categories__item--open .widget-categories__expander:after {
	transform: rotate(90deg);
}
.widget-categories__item--open .widget-categories__subs {
	height: auto;
	opacity: 1;
	visibility: visible;
	transition-delay: 0s, 0s, 0s;
}
.widget-categories--location--shop {
	padding: 20px;
}
.widget-comments {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
	padding: 28px;
}
.widget-comments a {
	color: inherit;
	transition: color 0.2s;
}
.widget-comments a:hover {
	color: #1b68a4;
}
.widget-comments__list {
	list-style: none;
	margin: 0;
	padding: 8px 0 4px;
}
.widget-comments__item + .widget-comments__item {
	margin-top: 26px;
}
.widget-comments__author {
	font-size: 15px;
	line-height: 18px;
}
.widget-comments__author a {
	border-bottom: 2px solid #ebebeb;
	transition: border 0.2s;
}
.widget-comments__author a:hover {
	border-color: rgba(26, 102, 255, 0.8);
}
.widget-comments__content {
	margin-top: 12px;
	font-size: 15px;
	line-height: 20px;
}
.widget-comments__meta {
	margin-top: 4px;
	font-size: 13px;
	color: #999;
	display: -ms-flexbox;
	display: flex;
	white-space: nowrap;
}
.widget-comments__date {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.widget-comments__name {
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
	padding-left: 20px;
}
.widget-comments__name:before {
	position: absolute;
	content: '';
	display: block;
	width: 4px;
	height: 4px;
	background: currentColor;
	border-radius: 2px;
	left: 8px;
	top: 8px;
}
.widget-filters {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
	padding: 20px;
}
.widget-filters__item {
	padding: 8px 20px;
	margin: 0 -20px;
	border-bottom: 2px solid #f0f0f0;
}
.widget-filters__item:first-child {
	border-top: 2px solid #f0f0f0;
}
.widget-filters__actions {
	padding-top: 24px;
	padding-bottom: 16px;
}
.widget-newsletter {
	background: #f5f5f5;
	border-radius: 2px;
	padding: 32px 30px 36px;
	text-align: center;
	font-size: 15px;
	line-height: 24px;
}
.widget-newsletter__title {
	font-size: 24px;
	padding-top: 6px;
	margin-bottom: 24px;
}
.widget-newsletter__text {
	margin-bottom: 22px;
}
.widget-newsletter__form {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.widget-posts {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
	padding: 28px;
}
.widget-posts__list {
	padding-top: 10px;
}
.widget-posts__item {
	display: -ms-flexbox;
	display: flex;
}
.widget-posts__item + .widget-posts__item {
	margin-top: 22px;
}
.widget-posts__image {
	width: 90px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-radius: 1.5px;
	overflow: hidden;
	-ms-flex-item-align: self-start;
	align-self: self-start;
	margin-right: 16px;
	position: relative;
}
.widget-posts__image :before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.2);
	opacity: 0;
	transition: opacity 0.6s;
	z-index: 1;
}
.widget-posts__image img {
	max-width: 100%;
	display: block;
}
.widget-posts__image:hover :before {
	opacity: 1;
}
.widget-posts__info {
	margin-top: -2px;
}
.widget-posts__name {
	font-size: 15px;
	line-height: 18px;
}
.widget-posts__name a {
	color: inherit;
	transition: color 0.2s;
}
.widget-posts__name a:hover {
	color: #1b68a4;
}
.widget-posts__date {
	margin-top: 2px;
	font-size: 13px;
	color: #999;
}
.widget-products {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
	padding: 20px;
}
.widget-products__list {
	padding-top: 8px;
}
.widget-products__item {
	display: -ms-flexbox;
	display: flex;
}
.widget-products__item + .widget-products__item {
	margin-top: 18px;
}
.widget-products__image {
	width: 50px;
	margin-right: 12px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.widget-products__image img {
	max-width: 100%;
}
.widget-products__name {
	margin-top: -2px;
	font-size: 14px;
	line-height: 17px;
}
.widget-products__name a {
	color: inherit;
	transition: 0.15s;
}
.widget-products__name a:hover {
	color: #1b68a4;
}
.widget-products__prices {
	padding-top: 2px;
	font-size: 14px;
	font-weight: 700;
	color: #3d464d;
}
.widget-products__new-price {
	color: #ff2626;
}
.widget-products__old-price {
	color: #b3b3b3;
	font-weight: 400;
	font-size: 12px;
	text-decoration: line-through;
}
.widget-search {
	height: 48px;
}
.widget-search__body {
	position: relative;
	height: 100%;
}
.widget-search__input {
	box-shadow: inset 0 0 0 2px #ededed;
	transition: box-shadow 0.2s;
	border-radius: 2px;
	height: 100%;
	width: 100%;
	background: transparent;
	border: none;
	padding: 10px 48px 10px 18px;
	color: inherit;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.widget-search__input:hover {
	box-shadow: inset 0 0 0 2px #d9d9d9;
}
.widget-search__input:focus {
	outline: none;
	box-shadow: inset 0 0 0 2px #1b68a4;
}
.widget-search__input::-webkit-input-placeholder {
	color: #999;
}
.widget-search__input:-ms-input-placeholder,
.widget-search__input::-ms-input-placeholder {
	color: #999;
}
.widget-search__input::placeholder {
	color: #999;
}
.widget-search__button {
	height: 48px;
	width: 48px;
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 0;
	background: transparent;
	border: none;
	padding: 0;
	fill: #ccc;
	transition: fill 0.2s;
}
.widget-search__button svg {
	position: absolute;
	left: calc(50% - 10px);
	top: calc(50% - 10px);
}
.widget-search__button:focus {
	outline: none;
}
.widget-search__button:focus,
.widget-search__button:hover {
	fill: #1b68a4;
}
.widget-tags {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
	padding: 28px;
}

@media (min-width: 1200px) {
	.container {
		max-width: 1140px !important;
	}
}
